import { defineStore } from 'pinia';
import i18n from '@/i18n';
import { get } from '@/services/api.service.js';
import { v4 as uuidv4 } from 'uuid';
import { track } from '@/services/tracking.service';

const timers = {};

function hasError(toasts, toastItem) {
  if (toastItem.type === 'error') {
    return toasts.some(
      (toast) => toast.type === 'error' && toast.content === toastItem.content
    );
  }
  return false;
}

export const useStore = defineStore('main', {
  state: () => ({
    globalMessage: {
      title: 'Title',
      message: 'Message',
      visible: false,
      type: 'error'
    },
    pageFocusVal: true,
    apiError: false,
    timezone: 'auto', // Auto | America/New_York | America/Denver
    languageVal: navigator.language || 'en',
    currency: 'USD',
    currencies: ['USD'],
    clock: '12hour',
    logLevel: import.meta.env.MODE === 'development' ? 'DEBUG' : 'ERROR',
    user: {
      displayName: '',
      email: '',
      phone: '',
      accessLevel: ''
    },
    permissions: [],
    roles: [],
    username: '',
    globals: {
      artist: null,
      timeframe: '12',
      dateselect: null,
      payee: null
    },
    artistList: [],
    payeeList: [],
    history: [],
    historyKept: 20,
    toasts: []
  }),

  getters: {
    pageFocus: (state) => (state.pageFocusVal ? 0 : -1),
    userPrefs: () => {
      const prefs = localStorage.getItem('userPrefs');
      const parsed = prefs ? JSON.parse(prefs) : null;
      return parsed;
    },
    language: (state) => {
      const prefs = localStorage.getItem('userPrefs');
      const parsed = prefs ? JSON.parse(prefs) : null;
      const locale =
        parsed?.settings?.language ??
        state.languageVal ??
        navigator.language ??
        'en';
      return locale || 'en';
    },
    hasPermission: (state) => (permissionArr) => {
      return permissionArr.some((permission) =>
        state.permissions.includes(permission)
      );
    },
    getHistory: (state) => state.history,
    lastEvent: (state) => state.history[state.history.length - 1],
    all: (state) => state.toasts,
    environment: () => {
      const prefs = localStorage.getItem('userPrefs');
      const parsed = prefs ? JSON.parse(prefs) : null;
      return parsed.environment;
    }
  },

  actions: {
    toggleGlobalMessage(onOff) {
      this.globalMessage.visible = onOff;
    },

    togglePageFocus(boolean) {
      this.pageFocus = boolean;
    },

    toggleApiError(boolean) {
      this.apiError = boolean;
    },
    setTimezone(timezone) {
      this.timezone = timezone;
    },
    setLanguage(language) {
      const prefs = localStorage.getItem('userPrefs');
      const parsed = prefs ? JSON.parse(prefs) : null;
      if (language === 'auto') {
        const languageTmp =
          navigator.language || navigator.userLanguage || 'en';
        language = languageTmp.split('-')[0];
      }
      if (parsed) {
        parsed.settings = parsed.settings || {};
        parsed.settings.language = language;
        localStorage.setItem('userPrefs', JSON.stringify(parsed));
      }
      this.languageVal = language
        ? language
        : navigator.language || navigator.userLanguage || 'en';
      i18n.global.locale.value = this.languageVal;
    },
    setCurrency(currency) {
      this.currency = currency;
    },
    setCurrencies(currencies) {
      this.currencies = currencies;
    },
    setClock(clock) {
      this.clock = clock;
    },
    getCurrency() {
      const cur = this.currency;
      const hasCur = this.currencies.includes(cur);
      const calcCur = hasCur ? cur : this.currencies[0] || 'USD';
      this.setCurrency(calcCur);
      return calcCur;
    },
    getLanguage() {
      const prefs = localStorage.getItem('userPrefs');
      const parsed = prefs ? JSON.parse(prefs) : null;
      let locale = parsed?.settings?.language ?? this.languageVal ?? 'en';
      if (locale === 'auto') {
        const userLanguage = navigator.language || navigator.userLanguage;
        locale = userLanguage.split('-')[0];
      }
      i18n.locale = locale;
      return locale;
    },
    setPrefs(prefs) {
      this.account = prefs.account;
      this.fullname = prefs.fullname;
      this.username = prefs.username;
      this.settings = prefs.settings;
      this.roles = prefs.roles;
      this.permissions = prefs.permissions;
      this.emulation = prefs.emulation;
    },
    setPayee(payeeObj) {
      this.globals.payee = payeeObj || null;
    },
    setArtist(artistObj) {
      this.globals.artist = artistObj || null;
    },
    setTimeframe(timeframeMonths) {
      this.globals.timeframe = timeframeMonths;
    },
    setDateselect(dateselectMonth) {
      this.globals.dateselect = dateselectMonth;
    },
    setArtists(artists) {
      this.artistList = artists;
    },
    resetAllFilters() {
      this.artistList = [];
      this.payeeList = [];
      this.globals.artist = null;
      this.globals.payee = null;
      this.globals.timeframe = '12';
      this.dateselect = null;
    },
    async updateArtists(q) {
      const query = q ? `?q=${q}` : '';
      const data = await get(`artist/list${query}`);
      this.setArtists(data);
    },
    async payeesArtists(q) {
      const query = q ? `?q=${q}` : '';
      const data = await get(`artist/list${query}`);
      this.setArtists(data);
    },
    addHistoryEvent(event) {
      this.history.push(event);
      if (this.history.length > this.historyKept) {
        this.cleanHistory();
      }
    },

    setHistoryLength(length) {
      this.historyKept = length;
      if (this.history.length > this.historyKept) {
        this.cleanHistory();
      }
    },

    cleanHistory() {
      if (this.history.length > this.historyKept) {
        const diff = this.history.length - this.historyKept;
        this.history.splice(0, diff);
      }
    },
    addToast(toastItem) {
      if (!hasError(this.toasts, toastItem)) {
        toastItem.id = toastItem.id || uuidv4();
        toastItem.type = toastItem.type || 'message';
        this.toasts.push(toastItem);
        this.addTimer(toastItem);
        track('messageShown', toastItem);
      }
    },

    incrementProgress(id) {
      const toast = this.toasts.find((toast) => toast.id === id);
      if (toast && toast.progress < 100) {
        toast.progress += 1;
      }
    },

    removeToast(id) {
      const index = this.toasts.findIndex((toast) => toast.id === id);
      if (index !== -1) {
        this.toasts.splice(index, 1);
        clearTimeout(timers[id]);
      }
    },

    removeAllToasts() {
      this.toasts = [];
      for (const id in timers) {
        clearTimeout(timers[id]);
      }
    },

    addTimer(toastItem) {
      if (toastItem.timer) {
        timers[toastItem.id] = setTimeout(() => {
          this.removeToast(toastItem.id);
        }, toastItem.timer * 1000);
      }
    }
  }
});
