<script setup>
import { deleteSession } from '@/services/api.service';
import VButton from '@/components/elements/button.vue';
import VCurrency from '@/components/elements/currency.vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/stores/main.store';
import { copyText } from '@/utilities/index.util.js';

defineProps({
  balance: {
    type: Number,
    default: 0
  }
});

const { userPrefs } = useStore();

const roles = userPrefs?.roles || [];
const permissions = userPrefs?.permissions || [];
const accountId = userPrefs?.account || '--';

const router = useRouter();
const onLogout = () => {
  deleteSession();
  router.push({ name: 'Login' });
};

const emulateModal = () => {
  router.push({ query: { modal: `emulate`, noScroll: true, modalSize: 900 } });
};
</script>
<template>
  <div class="avatar-menu">
    <div>
      <p>{{ userPrefs.fullname }}</p>
      <p class="avatar-menu__subdued">
        <button
          v-if="
            permissions.includes('emulate:rw') ||
            permissions.includes('emulate:ro') ||
            !!userPrefs.emulation
          "
          @click="copyText(userPrefs.username)"
        >
          {{ userPrefs.username }}
        </button>
        <span v-else>
          {{ userPrefs.username }}
        </span>
      </p>
      <p class="avatar-menu__subdued">
        <button
          v-if="
            permissions.includes('emulate:rw') ||
            permissions.includes('emulate:ro') ||
            !!userPrefs.emulation
          "
          @click="copyText(accountId)"
        >
          ID: {{ accountId }}
        </button>
      </p>
    </div>
    <div>
      <div class="avatar-menu__key-value">
        <span class="key">{{ $t('Role') }}</span>
        <span class="value">{{ roles.join(' ') }}</span>
      </div>
      <div class="avatar-menu__key-value" v-if="balance !== null">
        <span class="key">{{ $t('Balance') }}</span>
        <v-currency
          class="amount"
          :amount="balance"
          :extra="false"
          :monotone="true"
          lock="USD"
        />
      </div>
    </div>
    <div class="avatar-menu__key-value avatar-menu__actions">
      <v-button
        v-if="
          permissions.includes('emulate:rw') ||
          permissions.includes('emulate:ro') ||
          !!userPrefs.emulation
        "
        @click="emulateModal"
        >Emulate User</v-button
      >
      <v-button @click="onLogout" variant="secondary">Sign out</v-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.avatar-menu {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  padding: var(--spacing-medium);
  padding-bottom: 0;
  &__subdued {
    color: var(--color-text-disabled);
    font-size: var(--type-caption);
    button {
      color: var(--color-text-color-link);
      &:hover {
        color: var(--color-text-strong);
        text-decoration: underline;
      }
    }
  }
  &__key-value {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: var(--spacing-small);
    font-size: var(--type-small-body);
    .key {
      color: var(--color-primary-icon-default);
    }
    .value {
      font-weight: var(--type-weight-bold);
      text-transform: capitalize;
    }
  }
  &__actions {
    padding: 0 0 var(--spacing-medium) 0;
  }
}
</style>
