<script>
export default {
  name: 'VRoleColumn',
  components: {},
  props: {
    value: { type: [String, Number, Boolean], default: null }
  },
  computed: {
    formattedValue() {
      return this.value || '--';
    }
  },
  methods: {}
};
</script>

<template>
  <div class="v-column-role">
    <span>
      {{ formattedValue }}
    </span>
  </div>
</template>

<style lang="scss">
.v-column-role {
  user-select: text;
  color: var(--hex_table-column-standard-text);
  font-size: 15px;

  > span {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }
}
</style>
