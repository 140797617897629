// import './assets/main.css'

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import i18n from './i18n';
import { capitalize } from '@/utilities/string.util';

import App from './app.vue';
import router from './router';

const readOut = `%c${import.meta.env.APP_NAME}%c | Version: %c${import.meta.env.APP_VERSION}%c | Mode: %c${capitalize(import.meta.env.MODE)}%c | API: %c${import.meta.env.VITE_API_URI || window.location.origin}%c`;

console.log(
  readOut,
  'color: #63CFFF;',
  'color: #888;',
  'color: #63CFFF;',
  'color: #888;',
  'color: #63CFFF;',
  'color: #888;',
  'color: #63CFFF;',
  'color: #888;'
);

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(i18n);
app.use(router);
app.mount('#app');
