<script>
import { useStore } from '@/stores/main.store.js';
import ToastItem from './toast-item.vue';

export default {
  name: 'VToasts',
  components: {
    ToastItem
  },
  setup() {
    const toastStore = useStore();
    return {
      toasts: toastStore.toasts,
      closeToast: toastStore.removeToast
    };
  }
};
</script>

<template>
  <div class="v-toast">
    <transition-group name="list" tag="div" class="toasts">
      <toast-item
        v-for="toast in toasts"
        :key="toast.id"
        :toast="toast"
        :closeToast="closeToast"
      />
    </transition-group>
  </div>
</template>

<style lang="scss" scoped>
.v-toast {
  position: absolute;
  z-index: 100;
  right: var(--spacing-medium);
  bottom: var(--spacing-medium);
  width: 348px;

  .toasts > div {
    margin-top: var(--spacing-small);
  }
}
</style>
