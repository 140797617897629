<script setup>
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  nextTick
} from 'vue';
import { useStore } from '@/stores/main.store.js';
import VMessage from '@/components/root/message.vue';
import VModal from '@/components/root/modal.vue';
import VToasts from '@/components/root/toasts.vue';
import { fnDebounce } from '@/utilities/index.util.js';
import { DateTime } from 'luxon';
import PageNav from '@/components/root/page-nav/page-nav.vue';
import RouteBackdrop from '@/components/elements/route-backdrop.vue';
import { useRoute } from 'vue-router';
import * as amplitude from '@amplitude/analytics-browser';

// Initialize Amplitude
const amplitudeKey = import.meta.env.VITE_AMPLITUDE_API_KEY;
if (amplitudeKey) {
  amplitude.init(amplitudeKey, {
    autocapture: {
      attribution: false,
      pageViews: false,
      sessions: false,
      formInteractions: false,
      fileDownloads: true,
      elementInteractions: false
    }
  });
}

// Meta Info
document.title = 'RSDL + ADA';

// Component setup
const store = useStore();
const mounted = ref(true);
const height = ref(0);

const debounce = fnDebounce(() => {
  setHeight();
}, 200);

const globalMessage = computed(() => store.globalMessage);
const timezone = computed(() => store.timezone);
const route = useRoute();
const hasNav = computed(() => route.meta.hasNav);

watch(timezone, (zone) => {
  setTimezone(zone);
});

onMounted(() => {
  nextTick(() => {
    mounted.value = true;
  });
  setTimezone(timezone.value);
  setHeight();
  window.addEventListener('resize', debounce);

  const prefsObj = JSON.parse(localStorage.getItem('userPrefs'));
  if (prefsObj) {
    store.setPrefs(prefsObj);
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounce);
});

function setHeight() {
  height.value = window.innerHeight;
}

function setTimezone(zone) {
  zone = zone === 'auto' ? '' : zone;
  DateTime.local().setZone(zone);
}
</script>
<template>
  <div
    id="app"
    :style="{ height: `${height}px` }"
    :class="['theme-default', { mounted: mounted }]"
    class="theme-dark"
  >
    <v-message
      v-if="globalMessage.visible"
      class="static"
      :title="globalMessage.title"
      :message="globalMessage.message"
      :type="globalMessage.type"
    >
    </v-message>

    <div class="app-wrapper window">
      <transition name="trans-sidebar">
        <page-nav v-if="hasNav" />
      </transition>

      <div class="content">
        <route-backdrop />
        <main class="page scroll">
          <router-view v-slot="{ Component }">
            <transition name="trans-page" mode="out-in">
              <component :is="Component"></component>
            </transition>
          </router-view>
        </main>
      </div>
    </div>

    <v-toasts class="static" />
    <v-modal class="static" />
  </div>
</template>

<style lang="scss">
// Base Styles
@use './styles/reset.scss';
@use './styles/utilities/index.util.scss';
// Theme Styles
@use '@styles/tokens.theme.css';
@use '@styles/dark.theme.css';

#app {
  user-select: none;
  display: flex;
  position: relative;
  width: 100vw;
  flex-direction: column;
  will-change: opacity;
  box-sizing: border-box;
  overflow: hidden;
  color: var(--hex_text);
  text-align: left;
  transition: opacity 1s ease;

  &.mounted {
    opacity: 1;
  }

  .not-found {
    padding: 40px;

    button {
      display: inline-block;
      margin: 0 10px;
      color: var(--hex_purple-text);
    }
  }
}

.app-wrapper {
  background-color: var(--color-bg-level-01);
}

.window {
  display: flex;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: 1 1 auto;

  .v-page-nav {
    position: relative;
    z-index: 20;
  }

  .content {
    display: flex;
    position: relative;
    z-index: 10;
    flex: 1 1 100%;
    flex-direction: column;
  }

  .page {
    position: relative;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    &.full {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.static {
  flex: 0 0 auto;
}

// App Level Transitions
.trans-page-enter-active,
.trans-page-leave-active {
  transition: opacity 0.2s ease;
}

.trans-page-enter,
.trans-page-leave-active {
  opacity: 0;
}

.trans-sidebar-enter-active {
  transform: translateX(0);
  transition: transform 0.25s cubic-bezier(0.43, 0.67, 0.54, 1);
}

.trans-sidebar-leave-active {
  transform: translateX(0);
  transition: transform 0.4s cubic-bezier(0.43, 0.67, 0.54, 1);
}

.trans-sidebar-enter,
.trans-sidebar-leave-active {
  transform: translateX(-100%);
}

.trans-header-enter-active,
.trans-header-leave-active {
  transform: translateY(0);
  transition: transform 0.4s cubic-bezier(0.43, 0.67, 0.54, 1);
}

.trans-header-enter,
.trans-header-leave-active {
  transform: translateY(-100%);
}

.trans-footer-enter-active,
.trans-footer-leave-active {
  transform: translateY(0);
  transition: transform 0.4s cubic-bezier(0.43, 0.67, 0.54, 1);
}

.trans-footer-enter,
.trans-footer-leave-active {
  transform: translateY(100%);
}
</style>
