<script>
import { useStore } from '@/stores/main.store.js';

const modalPageConfig = {
  'name-edit': {
    label: 'edit name'
  },
  'email-edit': {
    label: 'edit email'
  },
  members: {
    label: 'Members'
  },
  managers: {
    label: 'Warranter'
  }
};

export default {
  name: 'VModal',
  data() {
    return {
      store: useStore(),
      windowVisible: false,
      staticTransition: true
    };
  },
  computed: {
    modalConfig() {
      return modalPageConfig[this.$route.query.modal] || {};
    },

    modalName() {
      return this.modalConfig.label;
    },

    modalSize() {
      return this.$route.query.modalSize || 500;
    },

    noScroll() {
      return this.$route.query.noScroll;
    },

    visible() {
      return !!this.$route.query.modal;
    }
  },
  watch: {
    visible(visible) {
      this.$nextTick(() => {
        this.windowVisible = visible;
      });

      if (visible) {
        this.$nextTick(() => {
          this.$el.focus();
        });
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.staticTransition = false;
    });
  },
  methods: {
    closeModal() {
      this.$router.push({});
    }
  }
};
</script>

<template>
  <div class="modal-wrapper pin">
    <transition name="fadeFast">
      <div
        v-show="visible"
        class="v-modal desktop"
        tabindex="0"
        role="dialog"
        :aria-labelledby="modalName"
        @keydown.esc.exact="closeModal"
      >
        <div class="background pin" @click="closeModal"></div>
        <transition name="slideDown">
          <div
            v-show="windowVisible"
            class="window"
            :class="{ scroll: !noScroll }"
            :style="{ width: `${modalSize}px` }"
          >
            <!-- <div class="header flex-row">
              <h2 v-if="modalName">
                {{modalName}}
              </h2>
              <div class="spacer"></div>
              <button
                @click="closeModal">
                Close
              </button>
            </div> -->
            <div id="modal-content" class="content">
              <!-- <div class="loading">{{ $t('Loading') }}</div> -->
            </div>
            <!-- <v-button @click="closeModal">Close</v-button> -->
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.modal-wrapper {
  overflow: hidden;
}

.v-modal {
  display: flex;
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  will-change: transform;

  .loading {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &.desktop {
    .background {
      background-color: color-mix(
        in srgb,
        var(--color-bg-level-04),
        transparent 50%
      );
    }

    .window {
      display: flex;
      position: relative;
      height: auto;
      min-height: 150px;
      max-height: 90%;
      flex: 0 0 auto;
      flex-direction: column;
      will-change: transform;
      overflow: visible;
      border-radius: var(--radius-small);
      background-color: var(--color-bg-level-01);

      &.scroll {
        overflow: auto;
      }

      .header {
        flex: 0 0 auto;
      }

      .content {
        width: 100%;
        flex: 1 1 1px;
        box-sizing: border-box;
      }

      .footer {
        flex: 0 0 auto;
      }
    }
  }
}

.modalSlide-enter-active {
  transition: all 0.35s ease;
}

.modalSlide-leave-active {
  transition: all 0.2s ease;
}

.modalSlide-enter,
.modalSlide-leave-to {
  opacity: 1;
  transform: translate3d(100%, 0, 0);
}

.modalSlide-enter-to,
.modalSlide-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
</style>
