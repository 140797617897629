<script setup>
import { useEventListener } from '@/directive/use-event-listener';
import { useOnClickOutside } from '@/directive/use-on-click-outside';
import { getPosition, getValidPosition } from '@/utilities/popover.util';
import { computed, onUpdated, ref, useSlots } from 'vue';

const props = defineProps({
  side: {
    type: String,
    default: 'right',
    validator: (value) => ['left', 'right', 'top', 'bottom'].includes(value)
  },
  alignment: {
    type: String,
    default: 'center',
    validator: (value) => ['start', 'center', 'end'].includes(value)
  },
  state: {
    type: String,
    default: 'auto',
    validator: (value) => ['auto', 'manual'].includes(value)
  }
});

const visible = ref(false);
const position = ref({ side: props.side, alignment: props.alignment });

const popover = ref();
const popoverDims = ref();

const showPopover = () => {
  popover.value?.showPopover();
  visible.value = true;
};
const hidePopover = () => {
  popover.value?.hidePopover();
  visible.value = false;
  popoverDims.value = null;
};

const trigger = ref();
const triggerDomRect = ref();

const slots = useSlots();
const getDimsAndDoms = (popDims) => {
  if (!popover.value || !visible.value || !slots?.default) return;
  const container = { width: window.innerWidth, height: window.innerHeight };
  const triggerRect = trigger.value?.getBoundingClientRect();
  triggerDomRect.value = triggerRect;
  const validPosition = getValidPosition(
    triggerRect,
    popDims,
    container,
    props.side,
    props.alignment
  );
  position.value = validPosition;
};

useEventListener(window, 'resize', () => getDimsAndDoms(popoverDims.value));
useEventListener(window, 'wheel', () => getDimsAndDoms(popoverDims.value));
useEventListener(window, 'keydown', (e) => {
  if (e.key === 'Escape') {
    hidePopover();
  }
});
useOnClickOutside(popover, hidePopover);

onUpdated(() => {
  if (!visible.value) return;
  if (popoverDims.value) return;
  const popDims = {
    width: popover.value?.offsetWidth,
    height: popover.value?.offsetHeight
  };
  getDimsAndDoms(popDims);
  popoverDims.value = popDims;
});

const contentPosition = computed(() => {
  const pos = getPosition(
    triggerDomRect.value,
    {
      width: popover.value?.offsetWidth,
      height: popover.value?.offsetHeight
    },
    position.value.alignment,
    position.value.side
  );
  return {
    top: `${pos?.top}px`,
    left: `${pos?.left}px`
  };
});
</script>

<template>
  <div class="v-popover">
    <div ref="trigger" class="v-popover__trigger">
      <slot
        name="trigger"
        :visible="visible"
        :show="showPopover"
        :hide="hidePopover"
      ></slot>
    </div>
    <div
      ref="popover"
      class="v-popover__content"
      :class="[`v-popover__content--${position.side}`]"
      :popover="state"
      :style="contentPosition"
    >
      <slot
        name="default"
        :side="position.side"
        :visible="visible"
        :show="showPopover"
        :hide="hidePopover"
      ></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-popover {
  position: relative;
  &__trigger {
    width: 100%;
    height: 100%;
  }
  &__content {
    background: transparent;
    border: none;
    padding: 0;
    overflow: visible;
    &--hidden {
      user-select: none;
      pointer-events: none;
      opacity: 0;
      position: fixed;
      top: 200vh;
    }
  }
}
</style>
