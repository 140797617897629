<script>
import VButton from '@/components/elements/button.vue';
import VTypeAheadInput from '@/components/inputs/type-ahead.input.vue';
import VToggleInput from '@/components/inputs/toggle.input.vue';
import VList from '@/components/list/list.vue';
import { get, emulateSession } from '@/services/api.service.js';
import { useStore } from '@/stores/main.store.js';
import ModalHeader from '../elements/modal-header.vue';
import ModalBody from '../elements/modal-body.vue';

export default {
  name: 'VEmulateModal',
  components: {
    VButton,
    VTypeAheadInput,
    VToggleInput,
    VList,
    ModalHeader,
    ModalBody
  },
  props: {
    isPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      store: useStore(),
      activeTab: null,
      emulateUser: '',
      warranter: false,
      states: [
        { label: this.$t('AllUsers'), value: false },
        { label: this.$t('Warranter', 2), value: true }
      ],
      foundUsers: [],
      recentUsers: [],
      loading: 0,
      show: false,
      accountTableDef: [
        {
          label: this.$t('FullName'),
          key: 'fullname',
          component: 'VStandardColumn',
          flexGrow: 10,
          flexShrink: -10
        },
        {
          label: this.$t('Username'),
          key: 'username',
          component: 'VStandardColumn',
          flexGrow: 20,
          flexShrink: -20
        },
        {
          label: this.$t('Role'),
          key: 'role',
          component: 'VStandardColumn',
          flexGrow: 0,
          flexShrink: 0,
          width: 80
        }
      ]
    };
  },
  computed: {
    userPrefs() {
      return this.store.userPrefs;
    },
    isEmulated() {
      return this.userPrefs && this.userPrefs.emulation;
    }
  },
  watch: {
    warranter: {
      handler: function () {
        this.getEmulateUsers();
      },
      deep: true
    }
  },
  created() {
    this.getData();
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.show = true;
      }, 250);
    });
  },
  methods: {
    async getData() {
      this.loading = 0;

      try {
        const f1 = this.getEmulateRecent();
        const f2 = this.getEmulateUsers();

        await Promise.all([f1, f2]);
      } catch (e) {
        console.warn(e);
      }

      this.loading = 100;
    },

    async getEmulateUsers(query) {
      this.emulateUser = query ? query.value : '';

      const string =
        this.emulateUser?.length > 2
          ? `&q=${encodeURIComponent(this.emulateUser)}`
          : '';
      const data = await get(
        `session/emulate?limit=250${string}&warranter=${this.warranter}`
      );

      this.foundUsers = Array.isArray(data) ? data : [];
    },

    async getEmulateRecent() {
      const data = await get('session/emulate/recent');

      this.recentUsers = Array.isArray(data) ? data : [];
    },

    async selectEmulateUser(user) {
      await emulateSession(user?.result?.username, this.isEmulated);
      window.location.replace('/dashboard');
    },

    async selectRecentAccount(row) {
      await emulateSession(row?.username, this.isEmulated);
      window.location.replace('/dashboard');
    }
  }
};
</script>

<template>
  <div v-show="show" class="v-emulate-modal">
    <modal-header
      :is-page="isPage"
      @click="() => this.$router.replace({ query: {} })"
      >{{ $t('EmulateAccount') }}</modal-header
    >
    <modal-body>
      <v-toggle-input
        v-model="warranter"
        class="toggle"
        :options="states"
      ></v-toggle-input>
      <v-type-ahead-input
        ref="emulateList"
        name="emulateList"
        :focus="false"
        :value="emulateUser"
        :label="$t('AccountToEmulate')"
        :placeholder="$t('placeholderEmulateAccount')"
        :results="foundUsers"
        @change="getEmulateUsers"
        @select="selectEmulateUser"
      >
        <template v-slot:result-header>
          <div class="label name">
            {{ $t('Name') }}
          </div>
          <div class="label username">
            {{ $t('Username') }}
          </div>
          <div class="label role">
            {{ $t('Role') }}
          </div>
        </template>
        <template v-slot:result="slot">
          <div class="value name">
            {{ slot.row.fullname }}
          </div>
          <div class="value username">
            {{ slot.row.username }}
          </div>
          <div class="value role capitalize">
            {{ slot.row.role }}
          </div>
        </template>
      </v-type-ahead-input>
      <div v-if="loading !== 0">
        <div class="modal-title">{{ $t('RecentlyEmulatedAccounts') }}</div>
        <v-list
          v-if="recentUsers && recentUsers.length > 0"
          :rows="recentUsers"
          :action="selectRecentAccount"
          :limit="10"
          :def="accountTableDef"
        />
        <div v-else class="loading">{{ $t('NoRecentlyEmulatedAccounts') }}</div>
      </div>
    </modal-body>
  </div>
</template>

<style lang="scss">
.v-emulate-modal {
  height: 100%;

  .capitalize {
    text-transform: capitalize;
  }

  .modal-title {
    flex: 0 0 auto;
    font-size: var(--type-small-headline);
    font-weight: var(--type-weight-semibold);
  }

  .toggle {
    margin-bottom: var(--spacing-medium);
  }

  .v-type-ahead-input {
    margin: 0 0 20px 0;

    .results {
      .value,
      .label {
        &.name {
          width: 300px;
          flex: 0 0 auto;
        }

        &.role {
          width: 80px;
          flex: 0 0 auto;
        }
      }
    }
  }

  .v-list {
    padding-bottom: 12px;

    .v-header-list {
      padding: 10px 0 2px 0;
    }

    .v-list-row:hover {
      cursor: pointer;
    }

    .v-list-row {
      .role {
        text-transform: capitalize;
      }
    }

    .rows {
      row-gap: 2px;
    }
  }

  .loading {
    padding: 10px 0 2px 0;
  }
}
</style>
