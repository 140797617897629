<script>
export default {
  name: 'VPercentColumn',
  props: {
    value: { type: Number, default: 0 },
    format: { type: [Function, String], default: null }
  },
  computed: {
    formattedValue() {
      if (typeof this.format === 'function') {
        return this.format(this.value) || '';
      } else {
        switch (this.format) {
          case 'percentage':
            return this.value ? this.value.toFixed(2) + '%' : '0.00%';
          case 'currency':
            return '$' + this.value;
        }

        if (this.value === null || this.value === undefined) {
          return '--';
        } else if (typeof this.value === 'object') {
          return this.value.join(', ');
        }

        return this.value || '';
      }
    }
  }
};
</script>

<template>
  <div class="v-percent-column">
    <div class="color-box"></div>
    {{ formattedValue }}
  </div>
</template>

<style lang="scss">
.v-percent-column {
  user-select: none;
  display: flex;
  align-items: center;
  color: var(--hex_table-column-standard-text);
  font-size: var(--type-caption);
  font-weight: 700;

  .color-box {
    width: var(--spacing-small);
    height: var(--spacing-small);
    margin-right: 5px;
    border-radius: var(--radius-full);
    background-color: #000000;
  }
}
</style>
