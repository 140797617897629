<script setup>
import NavMenu from '@/components/root/page-nav/nav-menu.vue';
import { useStore } from '@/stores/main.store';
import { deleteEmulation } from '@/services/api.service';
import VPopover from '@/components/elements/v-popover.vue';

const { userPrefs } = useStore();
const isEmulated = userPrefs?.emulation || false;
const userInitials = userPrefs?.fullname
  ?.replace(/[^a-zA-Z ]/g, '')
  .split(' ')
  .reduce((all, cur) => {
    const initial = cur.substring(0, 1);
    return all + initial;
  }, '')
  .substring(0, 2);

const emulateStop = async () => {
  await deleteEmulation();
  window.location.replace('/dashboard');
};
</script>

<template>
  <v-popover alignment="end">
    <template #trigger="{ show }">
      <div class="avatar__wrapper">
        <button
          class="avatar"
          :class="{ 'avatar--is-emulated': isEmulated }"
          @mouseenter="show"
          @focus="show"
        >
          {{ userInitials || '--' }}
        </button>
        <button
          v-if="isEmulated"
          class="avatar__emulate-stop"
          @click="emulateStop"
        >
          X
        </button>
      </div>
    </template>
    <template #default="{ hide }">
      <nav-menu @mouseleave="hide" @blur="hide">
        <slot></slot>
      </nav-menu>
    </template>
  </v-popover>
</template>

<style lang="scss" scoped>
.avatar__wrapper {
  position: relative;
  .avatar__emulate-stop {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(25%, -25%);
    border-radius: var(--radius-full);
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--type-caption);
    background-color: var(--color-primary-bg-default);
    color: var(--color-text-strong-on-light);
    &:hover {
      color: var(--color-primary-bg-default);
      background-color: var(--color-text-strong-on-dark);
    }
    &:focus-visible {
      box-shadow: 0 0 0 var(--border-stroke-strong)
        var(--color-secondary-border-default) inset;
    }
  }
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-full);
  background-color: var(--color-bg-level-05);
  padding: var(--spacing-small);
  height: var(--height-button-large);
  aspect-ratio: 1 / 1;
  &--is-emulated {
    box-shadow: 0 0 0 var(--border-stroke-strong)
      var(--color-primary-border-default) inset;
  }
  &:hover {
    background-color: var(--color-bg-level-03);
  }
  &:active {
    background-color: var(--color-bg-level-05);
  }
  &:focus-visible {
    box-shadow: 0 0 0 var(--border-stroke-strong)
      var(--color-secondary-border-default) inset;
  }
  &:disabled {
    pointer-events: none;
    background-color: var(--color-bg-level-01);
    color: var(--color-text-disabled);
  }
}
</style>
