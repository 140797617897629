<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { deepCopy, fnDebounce } from '@/utilities/index.util.js';

// Props
const props = defineProps({
  name: { type: String, required: true },
  label: { type: String, default: 'Field' },
  simple: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  modelValue: { type: [Number, String], default: null },
  placeholder: { type: String, default: null },
  autocomplete: { type: String, default: null },
  inputProps: {
    type: Object,
    default: () => {
      return { type: 'text' };
    }
  },
  focus: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  onFocus: {
    type: Function,
    default: () => {
      return {};
    }
  },
  blur: {
    type: Function,
    default: () => {
      return {};
    }
  },
  onkeypress: { type: Function, default: () => true },
  validateError: { type: Function, default: () => null },
  debounce: { type: Number, default: 200 },
  dynamic: { type: Boolean, default: false },
  message: { type: String, default: null },
  size: {
    type: String,
    default: 'large',
    validator: (val) => ['xlarge', 'large'].includes(val)
  },
  errorMessage: {
    type: [String, Boolean],
    default: null
  }
});

// Emits
const emit = defineEmits(['update:modelValue']);

// Local state
const localValue = ref(deepCopy(props.modelValue));
const error = ref(props.errorMessage);
const inputWidth = ref(0);

// Computed properties
const setValueFn = computed(() => {
  return fnDebounce(
    (val) => {
      const validationError = props.validateError(val);
      if (validationError) {
        error.value = validationError;
      } else {
        error.value = null;
      }
      emit('update:modelValue', val);
    },
    props.debounce,
    true
  );
});

// Watchers
watch(
  () => props.modelValue,
  (newVal) => {
    if (newVal !== localValue.value) {
      localValue.value = newVal;
    }
  }
);

watch(localValue, (newVal) => {
  setValueFn.value(newVal);
});

watch(
  () => props.errorMessage,
  (newErr) => {
    error.value = newErr;
  }
);

// Lifecycle hooks
onMounted(() => {
  if (props.focus) {
    const input = document.querySelector(`[ref="${props.name}-field"]`);
    if (input) input.focus();
  }
});

// Methods
const keyPress = (e) => {
  const val = e.target.value;

  if (props.onkeypress(val)) {
    localValue.value = e.target.value;
  } else {
    e.target.value = localValue.value;
  }

  if (props.dynamic) {
    inputWidth.value = e.target.value.length * 13 + 41;
  }
};

const localBlur = (e) => {
  error.value = props.validateError(localValue.value);
  props.blur(e);
};
</script>

<template>
  <div
    class="v-input v-generic-input"
    :class="[
      {
        dynamic: props.dynamic,
        disabled: props.disabled,
        simple: props.simple
      }
    ]"
  >
    <label v-if="!props.simple" :for="props.name">
      <span>{{ props.label }}</span>
      <span
        v-if="
          props.required &&
          !error &&
          (!props.modelValue || (props.modelValue && !props.modelValue.length))
        "
        class="required"
      >
        *
      </span>
    </label>

    <input
      :ref="`${props.name}-field`"
      v-bind="props.inputProps"
      :name="props.name"
      :id="props.name"
      :value="localValue"
      :disabled="props.disabled"
      :placeholder="props.placeholder"
      :autocomplete="props.autocomplete"
      class="input"
      :class="props.size"
      @focus="props.onFocus"
      @input="keyPress"
      @blur="localBlur"
      size="1"
    />
    <span v-if="error" class="error">
      {{ error }}
    </span>
    <span v-if="!error && message?.length" class="message">
      <slot name="message"></slot>
    </span>
  </div>
</template>

<style lang="scss">
.v-generic-input {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
  font-size: var(--type-small-body);
  width: 100%;

  &.simple {
    .input {
      padding: 0;
      border-radius: 0;
      height: auto;
      background-color: transparent;
      border: none;
    }
  }

  .message,
  .error {
    font-size: var(--type-caption);
  }
  .message {
    color: var(--color-text-strong);
  }
  .error {
    color: var(--color-error-text-default);
  }

  > label {
    display: flex;
    gap: var(--spacing-2xsmall);
    color: var(--color-text-strong);
  }

  .input {
    padding: 12px var(--spacing-medium);
    border-radius: var(--radius-xsmall);
    height: var(--height-button-large);
    background-color: var(--color-bg-default-emphasis);
    border: var(--border-stroke-default) solid
      var(--color-border-subtle-emphasis);
    color: var(--color-text-strong);

    &.xlarge {
      height: var(--height-button-xlarge);
    }

    &::placeholder {
      color: var(--color-text-subtle);
    }

    &:focus-visible {
      border-color: var(--color-secondary-border-pressed);
      box-shadow: 0 0 0 var(--border-stroke-default)
        var(--color-secondary-border-pressed);
      background: var(--color-bg-strong-emphasis);
    }

    &:disabled {
      border-color: transparent;
      background-color: var(--color-bg-default-emphasis);
      color: var(--color-text-disabled);
      &::placeholder {
        color: var(--color-text-disabled);
      }
    }
  }

  &:hover {
    .input {
      border-color: var(--color-border-stronger-emphasis);
      color: var(--color-text-strong);
      background-color: var(--color-bg-strong-emphasis);
      &::placeholder {
        color: var(--color-text-strong);
      }
    }
  }

  &.disabled {
    pointer-events: none;
    label,
    .message {
      color: var(--color-text-disabled);
    }
  }

  &.dynamic {
    width: auto;
    padding: 0;
  }
}
</style>
