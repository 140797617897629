import { useStore } from '@/stores/main.store.js';
import { createPinia, setActivePinia } from 'pinia';
import i18n from '@/i18n';
import { trackUser } from './tracking.service';

const apiBaseUrl = import.meta.env.VITE_API_URI;

// Ensure Pinia is active
let pinia;
function initializePinia() {
  if (!pinia) {
    pinia = createPinia();
    setActivePinia(pinia);
  }
}

export async function get(
  path,
  accept = 'application/json',
  content = 'application/json'
) {
  initializePinia();
  const store = useStore();
  const timeframe = store.globals.timeframe;
  const dateselect = store.globals.dateselect;
  const artist = store.globals.artist;
  const junction = path.includes('?') ? '&' : '?';

  path = `${apiBaseUrl}/api/${path}${junction}csrf=${getCookie('csrf')}`;

  if (dateselect) {
    path = path + `&month=${dateselect}`;
  } else if (timeframe) {
    path = path + `&months=${timeframe}`;
  }

  if (artist && artist.id) {
    path = path + `&artist=${artist.id}`;
  }

  const response = await fetch(path, {
    method: 'GET',
    headers: {
      accept: accept,
      'content-type': content
    },
    credentials: 'include'
  });

  if (response.status && (response.status === 502 || response.status === 503)) {
    const toastObj = {
      type: 'error',
      title: 'Error',
      content:
        'The RSDL server is not reachable. Please check your internet connection or try again later.',
      cancelable: true,
      timer: 10
    };

    store.toggleApiError(true);
    store.addToast(toastObj);

    return response;
  } else {
    let data;

    if (accept === 'text/csv') {
      const blob = await response.blob();

      data = {
        blob,
        name: response.headers.get('content-disposition').split('=')[1]
      };
    } else {
      data = await response.json();
    }

    store.toggleApiError(false);

    redirect(data);

    return data;
  }
}

function redirect(data) {
  if (data && data.status === 401) {
    deleteStorage();
    removeCookie('csrf', { path: '' });
    window.location.href = '/login';
  }
}

/**
 * @param {string} method - Type of Request, values POST, PUT, DELETE.
 * @param {object} body - Body of request, example {user: "", pass: ""}.
 */
export async function request(method, path, body = {}, overrideError = false) {
  initializePinia();
  const store = useStore();
  body.csrf = getCookie('csrf');

  const response = await fetch(`${apiBaseUrl}/api/${path}`, {
    method: method,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  });

  if (response.status && response.status === 502) {
    const toastObj = {
      type: 'error',
      title: i18n.global.t('Error'),
      content: i18n.global.t('ServerNotReachable'),
      cancelable: true,
      timer: 10
    };

    store.addToast(toastObj);
    store.toggleApiError(true);

    return response;
  } else if (response.status && response.status === 409) {
    const data = await response.json();

    const toastObj = {
      type: 'error',
      title: i18n.global.t('Error'),
      content: data.message
        ? data.message
        : i18n.global.t('FailedToCompleteAction'),
      cancelable: true,
      timer: 10
    };

    store.addToast(toastObj);
    store.toggleApiError(true);

    return response;
  } else if (response?.status === 404) {
    const data = await response.json();

    const toastObj = {
      type: 'error',
      title: i18n.global.t('NotFound'),
      content: data?.message || i18n.global.t('ResourceNotFound'),
      cancelable: true,
      timer: 10
    };

    store.addToast(toastObj);
    store.toggleApiError(true);

    return response;
  } else if (
    response.status === 200 ||
    response.status === 201 ||
    overrideError
  ) {
    const data = await response.json();
    store.toggleApiError(false);
    redirect(data);

    return data;
  } else {
    // Handle other response statuses if needed
  }
}

export async function upload(path, body = {}, files, onProgress) {
  const formData = new FormData();

  body.csrf = getCookie('csrf');
  formData.append('body', JSON.stringify(body));

  if (files instanceof File) {
    formData.append('file', files);
  } else if (files instanceof FileList) {
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }
  }

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${apiBaseUrl}/api/${path}`);

    xhr.upload.onprogress = (event) => {
      if (typeof onProgress === 'function') {
        if (event.lengthComputable) {
          onProgress((event.loaded / event.total) * 100);
        }
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200 || xhr.status === 201) {
        resolve(xhr.response);
      } else {
        reject(new Error(xhr.statusText));
      }
    };

    xhr.onerror = () => reject(new Error(xhr.statusText));
    xhr.send(formData);
  });
}

export async function createSession(username, password) {
  const response = await request('POST', 'session', { username, password });
  if (response) {
    switch (response.status) {
      case 200:
      case 201:
        setStorage(response);
        if (response.settings) {
          setLanguage(response.settings.language);
        }
        return response.status;
      default:
        return response.status;
    }
  }
}

export async function emulateSession(username, update = false) {
  const method = update ? 'PUT' : 'POST';
  const response = await request(method, 'session/emulate', { username });

  switch (response?.status) {
    case 200:
    case 201:
      setStorage(response);
      if (response?.settings) {
        setLanguage(response.settings.language);
      }
      return response?.status;
    default:
      return response?.status;
  }
}

export async function deleteEmulation() {
  const response = await request('DELETE', 'session/emulate');
  switch (response.status) {
    case 200:
      setStorage(response, 'userPrefs', false);
      if (response.settings) {
        setLanguage(response.settings.language);
      }
      window.location.href = '/admin';
      return response.status;
    default:
      return response.status;
  }
}

export async function deleteSession(sendSessionDelete = true) {
  if (sendSessionDelete) {
    await request('DELETE', 'session');
  }
  deleteStorage();
  removeCookie('csrf', { path: '' });
}

export async function extendSession() {
  const expireTime = 14400; // Idle time a user stays logged in
  const expired = +localStorage.getItem('authExpire') + expireTime < Date.now();
  if (expired) {
    const response = await request('PUT', 'session');
    if (response) {
      switch (response.status) {
        case 200:
          setStorage(response);
          if (response.settings) {
            setLanguage(response.settings.language);
          }
          return true;
        default:
          return false;
      }
    }
  } else {
    if (getCookie('csrf')) {
      return true;
    } else {
      return false;
    }
  }
}

function setStorage(responseObj, prefsName = 'userPrefs', setPrefs = true) {
  initializePinia();
  const store = useStore();
  const {
    account,
    fullname,
    username,
    roles,
    permissions,
    settings,
    emulation,
    environment
  } = responseObj;
  const prefsObj = {
    account,
    fullname,
    username,
    roles,
    permissions,
    settings,
    emulation,
    environment
  };
  trackUser(prefsObj);
  store.toggleApiError(true);
  localStorage.setItem('authExpire', Date.now().toString());
  localStorage.setItem(prefsName, JSON.stringify(prefsObj));
  if (setPrefs) {
    store.setPrefs(prefsObj);
  }
}

function setLanguage(locale) {
  initializePinia();
  const store = useStore();
  store.setLanguage(locale);
}

function deleteStorage() {
  localStorage.removeItem('authExpire');
  localStorage.removeItem('userPrefs');
}

export async function downloadFile(uri, start, distributor) {
  const query = {
    start,
    distributor
  };

  for (const prop in query) {
    if (query[prop] && (query[prop].length > 0 || query[prop] > 0)) {
      uri += `&${prop}=${encodeURIComponent(query[prop])}`;
    }
  }
  const data = await get(uri, 'text/csv', 'application/json');
  const url = window.URL.createObjectURL(data.blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = data.name || 'sample.csv';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

// Helper functions for native cookie handling
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function getAllCookies() {
  const cookies = {};
  document.cookie.split(';').forEach((cookie) => {
    const [name, value] = cookie.split('=').map((c) => c.trim());
    cookies[name] = value;
  });
  return cookies;
}

function removeCookie(name) {
  document.cookie = `${name}=; Max-Age=-99999999;`;
}
