<script>
import { track } from '@/services/tracking.service';

export default {
  name: 'VToggleInput',
  props: {
    disabled: { type: Boolean, default: false },
    modelValue: {
      type: [Boolean, String],
      default: null,
      validator: function (value) {
        return typeof value === 'boolean' || typeof value === 'string';
      }
    },
    options: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      elWidth: 0,
      optionRefs: [],
      sliderWidth: 0,
      sliderX: 0
    };
  },

  computed: {
    selectedIndex() {
      return this.options.findIndex(
        (option) => option.value === this.modelValue
      );
    }
  },

  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },

  updated() {
    this.setSliderStyle();
  },

  methods: {
    selectOption(option) {
      this.$emit('update:modelValue', option.value);
      if ('link' in option && option.link) {
        this.$router.push(option.link);
      }
      track('tabPress', {
        label: option.label
      });
    },

    setElWidth() {
      this.elWidth = this.$el.clientWidth;
    },

    setOptionRef(el) {
      if (el) {
        this.optionRefs.push(el);
      }
    },

    handleResize() {
      this.setElWidth();
    },

    setSliderStyle() {
      const activeOption = this.optionRefs[this.selectedIndex];
      const domRect = activeOption.getBoundingClientRect();
      const containerRect = this.$el.getBoundingClientRect();
      this.sliderWidth = domRect.width;
      // get x relative to container
      this.sliderX = `calc(${domRect.x - containerRect.x}px)`;
    }
  },

  mounted() {
    this.setElWidth();
    window.addEventListener('resize', this.handleResize);
    this.setSliderStyle();
    // Hacky but focus the calced property to recalculate after loaded in something like a model
    this.$nextTick(() => {
      this.selectOption(this.options[1]);
      this.$nextTick(() => {
        this.selectOption(this.options[0]);
      });
    });
  }
};
</script>

<template>
  <div class="v-input v-toggle-input" :class="{ disabled }">
    <div
      class="slider"
      :style="{
        width: `${this.sliderWidth}px`,
        transform: `translateX(${this.sliderX})`
      }"
    ></div>
    <div class="options">
      <div
        v-for="(option, index) in options"
        :key="option.value"
        tabindex="0"
        class="option"
        :class="[{ active: index === selectedIndex }, 'val-' + option.value]"
        @click="selectOption(option)"
        @keydown.enter="selectOption(option)"
      >
        <span :ref="setOptionRef" class="label ellipsis">{{
          option.label
        }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-toggle-input {
  position: relative;
  width: 100%;
  border-bottom: var(--border-stroke-default) solid
    var(--color-border-strong-emphasis);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .slider {
    position: absolute;
    z-index: 1;
    bottom: 0;
    height: var(--border-stroke-strong);
    background-color: var(--color-primary-border-default);
    border-radius: var(--radius-xsmall);
    transition:
      transform 0.2s ease,
      opacity 0.2s ease;
  }

  .options {
    display: flex;
    position: relative;
    gap: var(--spacing-large);
    z-index: 2;
    align-items: center;

    .option {
      display: flex;
      position: relative;
      z-index: 2;
      min-width: max-content;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      color: var(--hex_toggle-text);
      border-radius: 2px;
      padding-bottom: var(--spacing-small);
      cursor: pointer;
      transition: color 0.2s ease;

      .label {
        width: 100%;
        text-align: center;
        flex-wrap: wrap;
        color: var(--color-text-subtle);
        font-size: var(--type-small-body);
        font-weight: var(--type-weight-semibold);
      }

      &:hover .label {
        color: var(--color-text-hover);
      }

      &.active .label {
        color: var(--color-text-strong);
      }
      &:focus-visible {
        .label {
          color: var(--color-text-hover);
        }
      }
    }
  }
}
</style>
