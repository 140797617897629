export * from './string.util';
export * from './fn.util';
export * from './chart.util';
export * from './array.util';
export * from './object.util';
export * from './html.util';
export * from './typing.util';
export * from './router.util';
export * from './validators.util';

import { DateTime } from 'luxon';

export function deepCopy(item) {
  if (!item) {
    return item;
  } // null, undefined values check

  var types = [Number, String, Boolean],
    result;

  // normalizing primitives if someone did new String('aaa'), or new Number('444');
  types.forEach(function (type) {
    if (item instanceof type) {
      result = type(item);
    }
  });

  if (typeof result === 'undefined') {
    if (Object.prototype.toString.call(item) === '[object Array]') {
      result = [];
      item.forEach(function (child, index) {
        result[index] = deepCopy(child);
      });
    } else if (typeof item === 'object') {
      // testing that this is DOM
      if (item.nodeType && typeof item.cloneNode === 'function') {
        result = item.cloneNode(true);
      } else if (!item.prototype) {
        // check that this is a literal
        if (item instanceof Date) {
          result = new Date(item);
        } else {
          // it is an object literal
          result = {};
          for (var i in item) {
            result[i] = deepCopy(item[i]);
          }
        }
      } else {
        if (item.constructor) {
          result = new item.constructor();
        } else {
          result = item;
        }
      }
    } else {
      result = item;
    }
  }

  return result;
}

export function dateFormat(
  milliSeconds,
  dateFormat = 'LLLL d, yyyy t ZZZZ',
  timezone
) {
  if (timezone) {
    if (typeof milliSeconds === 'number') {
      return DateTime.fromMillis(milliSeconds)
        .setZone(timezone)
        .toFormat(dateFormat);
    } else if (typeof milliSeconds === 'string') {
      return DateTime.fromISO(milliSeconds)
        .setZone(timezone)
        .toFormat(dateFormat);
    } else {
      return milliSeconds;
    }
  } else {
    if (typeof milliSeconds === 'number') {
      return DateTime.fromMillis(milliSeconds).toFormat(dateFormat);
    } else if (typeof milliSeconds === 'string') {
      return DateTime.fromISO(milliSeconds).toFormat(dateFormat);
    } else {
      return milliSeconds;
    }
  }
}

export function useResize(callback) {
  const handleResize = () => {
    if (callback) {
      callback();
    }
  };

  // Add event listener
  window.addEventListener('resize', handleResize);

  // Return a function to remove the listener
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}
