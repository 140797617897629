<script>
import { formatMoney } from '@/utilities/index.util.js';

export default {
  name: 'VCurrency',
  props: {
    amount: { type: [Number, String], default: 0 },
    extra: { type: Boolean, default: true },
    monotone: { type: [String, Boolean], default: false },
    lock: { type: [String, Boolean], default: false },
    size: {
      type: String,
      default: 'large',
      validator: (value) => {
        return ['large', 'medium', 'small'].includes(value);
      }
    }
  },
  data() {
    return {
      formatMoney
    };
  },
  computed: {
    value() {
      return formatMoney(this.amount, this.extra, this.lock);
    },
    dollars() {
      return this.value.toString().split('.')[0];
    },
    cents() {
      return this.value.toString().split('.')[1];
    },
    tone() {
      return this.monotone ? 'uncolored' : 'colored';
    }
  }
};
</script>

<template>
  <div class="v-currency" :class="`v-currency--${size}`">
    <span class="dollars">{{ dollars }}</span
    ><span :class="tone">.{{ cents }}</span>
    <svg
      v-if="lock"
      width="13"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="lock"
      :class="tone"
    >
      <path
        d="M6.11106 6.62037C6.11106 6.33912 5.83745 6.11111 5.49995 6.11111C5.16244 6.11111 4.88883 6.33912 4.88883 6.62037V8.0463C4.88883 8.32755 5.16244 8.55556 5.49995 8.55556C5.83745 8.55556 6.11106 8.32755 6.11106 8.0463V6.62037Z"
        fill="var(--color-icon-primary)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.62856 2.66832C2.62856 1.19464 3.91412 0 5.49995 0C7.08577 0 8.37133 1.19464 8.37133 2.66832V3.92079H8.60573C9.253 3.92079 9.77772 4.40841 9.77772 5.0099V8.82178C9.77772 10.0248 8.72828 11 7.43374 11H3.56616C2.27161 11 1.22217 10.0248 1.22217 8.82178V5.0099C1.22217 4.40841 1.74689 3.92079 2.39416 3.92079H2.62856V2.66832ZM7.19934 2.66832V3.92079H3.80055V2.66832C3.80055 1.79615 4.5614 1.08911 5.49995 1.08911C6.43849 1.08911 7.19934 1.79615 7.19934 2.66832ZM2.39416 5.0099V8.82178C2.39416 9.42328 2.91889 9.91089 3.56616 9.91089H7.43374C8.081 9.91089 8.60573 9.42329 8.60573 8.82178V5.0099H2.39416Z"
        fill="var(--color-icon-primary)"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.v-currency {
  .dollars {
    color: var(--color-text-strong);
    font-size: var(--type-small-headline);
  }

  .colored {
    color: var(--color-text-subtle);
    font-size: var(--type-small-body);
    font-weight: var(--type-weight-semibold);
  }

  .uncolored {
    color: var(--hex_currency-dollars);
    font-size: var(--type-small-body);
    font-weight: var(--type-weight-semibold);
  }

  &--medium {
    .dollars {
      font-size: var(--type-small-body);
      font-weight: var(--type-weight-regular);
    }

    .colored,
    .uncolored {
      font-size: var(--type-caption);
      font-weight: var(--type-weight-regular);
    }
  }
  &--small {
    .dollars {
      font-size: var(--type-caption);
      font-weight: var(--type-weight-bold);
    }

    .colored,
    .uncolored {
      font-size: var(--type-caption);
      font-weight: var(--type-weight-bold);
    }
  }

  .lock {
    display: inline-block;
    margin: 0 0 0 3px;
    position: relative;
    top: 1px;
    path {
      fill: var(--color-primary-icon-default);
    }
    &.uncolored {
      path {
        fill: var(--color-icon-disabled);
      }
    }
  }
}
</style>
