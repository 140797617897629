<script>
import VButton from '@/components/elements/button.vue';

export default {
  name: 'VActionColumn',
  components: {
    VButton
  },
  props: {
    value: { type: [String, Number], default: null },
    row: { type: Object, default: null },
    btnLabel: { type: [String, Function], default: null },
    action: { type: Function, default: () => {} },
    showAction: { type: Boolean, default: true },
    hidden: { type: [Boolean, Function], default: false },
    disabled: { type: [Boolean, Function], default: false }
  },
  computed: {
    buttonLabel() {
      if (typeof this.btnLabel === 'function') {
        return this.btnLabel(this.row);
      }

      return this.btnLabel;
    },
    isHidden() {
      if (typeof this.hidden === 'function') {
        return this.hidden(this.row);
      }

      return this.hidden;
    },
    isDisabled() {
      if (typeof this.disabled === 'function') {
        return this.disabled(this.row);
      }

      return this.disabled;
    }
  },
  created() {},
  methods: {}
};
</script>

<template>
  <div
    v-if="showAction"
    class="v-column-action"
    :class="[{ hidden: isHidden }]"
  >
    <v-button
      variant="secondary"
      :disabled="isDisabled"
      size="small"
      @click="action(String(value), $event, row)"
      >{{ buttonLabel }}</v-button
    >
  </div>
</template>

<style lang="scss">
.v-column-action {
  user-select: text;
  color: var(--color-text-strong);
  font-size: var(--type-small-body);

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
