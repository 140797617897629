export function fnThrottle(fn, delay = 100) {
  let lastCall = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return fn(...args);
  };
}

export function fnDebounce(fn, delay = 200, runFirst = false) {
  let timerId;
  let isFirstRun = true;
  return function (...args) {
    if (runFirst && isFirstRun) {
      isFirstRun = false;
      fn(...args);
      return;
    }
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}
