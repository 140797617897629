<script>
import VTooltip from '@/components/elements/v-tooltip.vue';

export default {
  name: 'VLabelValue',
  components: {
    VTooltip
  },
  props: {
    label: { type: String, default: null },
    placeholder: { type: String, default: '--' },
    tooltip: { type: String, default: null }, // {filled: Boolean, position: String, text: String}
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary'].includes(value)
    }
  }
};
</script>

<template>
  <div class="v-label-value" :class="`v-label-value--${variant}`">
    <div class="label-wrapper">
      <v-tooltip size="small">
        <template #trigger>
          <div class="label">
            {{ label }}
          </div>
        </template>
        {{ tooltip }}
      </v-tooltip>
    </div>
    <div class="value ellipsis">
      <slot>
        <div class="placeholder">{{ placeholder }}</div>
      </slot>
    </div>
  </div>
</template>

<style lang="scss">
.v-label-value {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xsmall);
  .label-wrapper {
    display: flex;

    .label {
      padding: 0;
      color: var(--color-text-subtle);
      font-size: var(--type-small-body);
      line-height: var(--type-small-headline);
      white-space: nowrap;
    }
  }

  .value {
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 0;
    font-size: var(--type-medium-headline);
    color: var(--color-text-default);
  }

  // Sub classes

  &.success {
    .label {
      color: var(--color-success-text-default);
    }
  }

  &.error {
    .label {
      color: var(--color-error-text-default);
    }
  }

  &.blue {
    .label {
      color: var(--color-info-text-default);
    }
  }

  &--secondary {
    .value {
      font-size: var(--type-small-body);
    }
  }
}
</style>
