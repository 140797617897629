<script>
import { deepCopy } from '@/utilities/index.util.js';
import VGenericInput from '@/components/inputs/generic.input.vue';
import VFilterGenericInput from '@/components/inputs/filter-generic.input.vue';

export default {
  name: 'VTypeAheadInput',
  components: {
    VGenericInput,
    VFilterGenericInput
  },
  props: {
    name: { type: String, required: true },
    label: { type: String, default: 'Field' },
    value: { type: [Number, String], default: null },
    results: { type: Array, default: () => [] },
    placeholder: { type: String, default: null },
    loading: { type: Number, default: 100 },
    index: { type: Number, default: 0 },
    focus: { type: Boolean, default: false },
    dynamic: { type: Boolean, default: false },
    inline: { type: Boolean, default: false }
  },
  data() {
    return {
      localValue: this.value,
      open: false,
      selectedItem: 0
    };
  },

  watch: {
    value(newVal) {
      if (newVal !== this.localValue) {
        this.localValue = newVal;
      }
    },
    localValue: {
      handler: function (val) {
        // if (newVal !== this.localValue) {
        this.$emit('change', { value: val, index: this.index });
        // }
      }
    }
  },

  mounted() {
    window.addEventListener('keydown', this.upDownKeyPress);
  },

  beforeUnmount() {
    window.removeEventListener('keydown', this.upDownKeyPress);
  },

  methods: {
    upDownKeyPress(e) {
      if (
        this.open &&
        (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Enter')
      ) {
        e.preventDefault();
        let index = deepCopy(this.selectedItem);
        switch (e.key) {
          case 'ArrowUp':
            index--;
            break;
          case 'ArrowDown':
            index++;
            break;
          case 'Enter':
            this.select(this.selectedItem);
            this.deactivate();
            break;
        }
        index =
          index > this.results.length - 1 ? this.results.length - 1 : index;
        index = index < 0 ? 0 : index;
        this.selectedItem = index;
      }
    },

    windowClick(e) {
      if (!this.$el.contains(e.target)) {
        this.deactivate();
      }
    },

    activate() {
      this.selectedItem = 0;
      this.open = true;
      this.$emit('open');
      window.addEventListener('click', this.windowClick);
    },

    deactivate() {
      this.open = false;
      this.$emit('close');
      window.removeEventListener('click', this.windowClick);
    },

    select(i) {
      const result = this.results[i];
      this.$emit('select', { result, index: this.index });
      this.deactivate();
    },

    change(i) {
      this.selectedItem = i;
    }
  }
};
</script>

<template>
  <div class="v-type-ahead-input" :class="[{ dynamic: dynamic }]">
    <v-generic-input
      v-if="!inline"
      v-model="localValue"
      :focus="focus"
      :name="name"
      :label="label"
      :placeholder="placeholder"
      :on-focus="activate"
      :debounce="500"
      :dynamic="dynamic"
    ></v-generic-input>
    <v-filter-generic-input
      v-else
      v-model="localValue"
      :focus="focus"
      :name="name"
      :label="label"
      :placeholder="placeholder"
      :on-focus="activate"
      :debounce="500"
      :dynamic="dynamic"
      :small="true"
    ></v-filter-generic-input>
    <div v-if="open" class="results scroll">
      <div v-if="results && results.length" class="result-items">
        <div class="header">
          <slot name="result-header"> </slot>
        </div>
        <div
          v-for="(result, i) in results"
          :key="i"
          class="result"
          :class="{ selected: i === selectedItem }"
          @click.stop="select(i)"
          @mouseover="change(i)"
        >
          <slot name="result" :row="result" :index="i"></slot>
        </div>
      </div>
      <div v-else class="not-found">
        Not found - click anywhere to use entered text
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.v-type-ahead-input {
  position: relative;
  width: 100%;
  .results {
    position: absolute;
    z-index: 10;
    top: calc(100% + var(--spacing-xsmall));
    right: 0;
    left: 0;
    max-height: 300px;
    overflow-y: auto;
    padding: 12px 0;
    border-radius: var(--radius-xsmall);
    background-color: var(--color-bg-level-01);
    border: var(--border-stroke-default) solid
      var(--color-border-strong-emphasis);
    box-shadow: var(--elevation-level-01);

    .header,
    .result {
      display: flex;
      padding: 0 var(--spacing-medium);
      font-size: var(--type-medium-body);

      .label,
      .value {
        white-space: nowrap;
        min-width: 0;
        flex: 1 1 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }

    .header {
      color: var(--color-text-subtle);
      .label {
        font-size: var(--type-small-body);
        padding-bottom: 12px;
      }
    }

    .result {
      padding: 12px var(--spacing-medium);
      cursor: pointer;
      &:hover {
        background-color: var(--color-bg-strong-emphasis);
      }

      &.selected {
        background-color: var(--color-bg-strong-emphasis);
      }
    }

    .not-found {
      margin: 12px var(--spacing-medium);
    }
  }

  &.dynamic {
    display: inline-block;
    width: auto;

    .results {
      width: 300px;
    }
  }

  &.disabled {
    pointer-events: none;
    .label,
    .message {
      color: var(--color-text-disabled);
    }
  }
}
</style>
