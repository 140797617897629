const getIsOffScreen = (domRect, container) => {
  if (!domRect || !container) return false;
  return (
    domRect?.top < 0 ||
    domRect?.left < 0 ||
    domRect?.bottom > container?.height ||
    domRect?.right > container?.width
  );
};

const getRightPosition = (triggerRect, popoverRect, alignment) => {
  if (!triggerRect || !popoverRect) return;
  switch (alignment) {
    case 'start':
      return {
        top: triggerRect.top,
        left: triggerRect.right,
        bottom: triggerRect.top + popoverRect.height,
        right: triggerRect.right + popoverRect.width
      };
    case 'end':
      return {
        top: triggerRect.bottom - popoverRect.height,
        left: triggerRect.right,
        bottom: triggerRect.bottom,
        right: triggerRect.right + popoverRect.width
      };
    default:
      return {
        top: triggerRect.top + triggerRect.height / 2 - popoverRect.height / 2,
        left: triggerRect.right,
        bottom:
          triggerRect.top + triggerRect.height / 2 + popoverRect.height / 2,
        right: triggerRect.right + popoverRect.width
      };
  }
};

const getLeftPosition = (triggerRect, popoverRect, alignment) => {
  if (!triggerRect || !popoverRect) return;
  switch (alignment) {
    case 'start':
      return {
        top: triggerRect.top,
        left: triggerRect.left - popoverRect.width,
        bottom: triggerRect.top + popoverRect.height,
        right: triggerRect.left
      };
    case 'end':
      return {
        top: triggerRect.bottom - popoverRect.height,
        left: triggerRect.left - popoverRect.width,
        bottom: triggerRect.bottom,
        right: triggerRect.left
      };
    default:
      return {
        top: triggerRect.top + triggerRect.height / 2 - popoverRect.height / 2,
        left: triggerRect.left - popoverRect.width,
        bottom:
          triggerRect.top + triggerRect.height / 2 + popoverRect.height / 2,
        right: triggerRect.left
      };
  }
};

const getTopPosition = (triggerRect, popoverRect, alignment) => {
  if (!triggerRect || !popoverRect) return;
  switch (alignment) {
    case 'start':
      return {
        top: triggerRect.top - popoverRect.height,
        left: triggerRect.left,
        bottom: triggerRect.top,
        right: triggerRect.left + popoverRect.width
      };
    case 'end':
      return {
        top: triggerRect.top - popoverRect.height,
        left: triggerRect.right - popoverRect.width,
        bottom: triggerRect.top,
        right: triggerRect.right
      };
    default:
      return {
        top: triggerRect.top - popoverRect.height,
        left: triggerRect.left + triggerRect.width / 2 - popoverRect.width / 2,
        bottom: triggerRect.top,
        right: triggerRect.left + triggerRect.width / 2 + popoverRect.width / 2
      };
  }
};

const getBottomPosition = (triggerRect, popoverRect, alignment) => {
  if (!triggerRect || !popoverRect) return;
  switch (alignment) {
    case 'start':
      return {
        top: triggerRect.bottom,
        left: triggerRect.left,
        bottom: triggerRect.bottom + popoverRect.height,
        right: triggerRect.left + popoverRect.width
      };
    case 'end':
      return {
        top: triggerRect.bottom,
        left: triggerRect.right - popoverRect.width,
        bottom: triggerRect.bottom + popoverRect.height,
        right: triggerRect.right
      };
    default:
      return {
        top: triggerRect.bottom,
        left: triggerRect.left + triggerRect.width / 2 - popoverRect.width / 2,
        bottom: triggerRect.bottom + popoverRect.height,
        right: triggerRect.left + triggerRect.width / 2 + popoverRect.width / 2
      };
  }
};

export const getPosition = (triggerRect, popoverRect, alignment, side) => {
  switch (side) {
    case 'right':
      return getRightPosition(triggerRect, popoverRect, alignment);
    case 'left':
      return getLeftPosition(triggerRect, popoverRect, alignment);
    case 'top':
      return getTopPosition(triggerRect, popoverRect, alignment);
    case 'bottom':
      return getBottomPosition(triggerRect, popoverRect, alignment);
    default:
      return getRightPosition(triggerRect, popoverRect, alignment);
  }
};

// returns {[side]: alignment[]}
const getValidPositions = (triggerRect, popoverRect, container) => {
  if (!triggerRect || !popoverRect) return {};
  const alignments = ['start', 'center', 'end'];
  const sides = ['right', 'left', 'top', 'bottom'];
  const positions = sides.reduce((acc, side) => {
    return {
      ...acc,
      [side]: alignments.reduce((acc, alignment) => {
        const position = getPosition(triggerRect, popoverRect, alignment, side);
        const isOffScreen = getIsOffScreen(position, container);
        if (isOffScreen) return acc;
        return [...acc, alignment];
      }, [])
    };
  }, {});
  return positions;
};

const preferredSideOrder = (side) => {
  switch (side) {
    case 'right':
      return ['left', 'bottom', 'top'];
    case 'left':
      return ['right', 'bottom', 'top'];
    case 'top':
      return ['bottom', 'right', 'left'];
    case 'bottom':
      return ['top', 'right', 'left'];
    default:
      return ['left', 'bottom', 'top'];
  }
};

// recursive function to find valid position
export const getValidPosition = (
  triggerRect,
  popoverRect,
  container,
  side,
  alignment
) => {
  if (!triggerRect || !popoverRect) return;
  const position = getPosition(triggerRect, popoverRect, alignment, side);
  const isOffScreen = getIsOffScreen(position, container);
  if (!isOffScreen) return { side, alignment };
  const preferredSides = preferredSideOrder(side);
  const validPositions = getValidPositions(triggerRect, popoverRect, container);
  if (validPositions[side].length > 0) {
    const preferredAlignment = validPositions[side][0];
    return { side, alignment: preferredAlignment };
  }
  for (let i = 0; i < preferredSides.length; i++) {
    const preferredSide = preferredSides[i];
    if (validPositions[preferredSide].length > 0) {
      const preferredAlignment = validPositions[preferredSide][0];
      const alignmentIncludedInValidPositions =
        validPositions[preferredSide].includes(alignment);
      if (alignmentIncludedInValidPositions) {
        return { side: preferredSide, alignment };
      }
      return getValidPosition(
        triggerRect,
        popoverRect,
        container,
        preferredSide,
        preferredAlignment
      );
    }
  }
  return { side, alignment };
};
