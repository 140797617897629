<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/stores/main.store.js';
import { makeCssClass } from '@/utilities/index.util.js';
import NavMenuItem from './nav-menu-item.vue';
import NavMenuLink from './nav-menu-link.vue';
import VAvatar from './avatar.vue';
import AvatarMenu from './avatar-menu.vue';
import { get } from '@/services/api.service';
import IconR from '@/components/icons/icon-r.vue';
import IconAda from '@/components/icons/icon-ada.vue';
import IconCatalog from '@/components/icons/icon-catalog.vue';
import IconLedger from '@/components/icons/icon-ledger.vue';
import IconReporting from '@/components/icons/icon-reporting.vue';
import IconUtilities from '@/components/icons/icon-utilities.vue';
import VEmulateModal from '@/components/molecules/emulate-modal.vue';

const route = useRoute();
const router = useRouter();
const store = useStore();
const permissions = store?.userPrefs?.permissions || [];

// these route configs are used to generate the navigation menu
// id: unique identifier for the route
// name: display name for the route, used in the menu/popover for display
// icon: the icon component to display in the side nav
// path: the path to navigate to when the item itself is clicked
// position: the position of the menu item relative to the icon
// children: an array of child routes to display in the menu
const reportingRoutes = {
  id: 'reporting',
  name: 'Reporting',
  icon: IconReporting,
  path: '/dashboard',
  position: 'start',
  children: [
    {
      name: 'Dashboard',
      path: '/dashboard'
    },
    {
      name: 'Analytics',
      path: '/analytics'
    }
  ]
};
const catalogRoutes = {
  id: 'catalog',
  name: 'Catalog',
  icon: IconCatalog,
  path: '/products',
  position: 'start',
  children: [
    {
      name: 'Products',
      path: '/products'
    },
    {
      name: 'Recoupments',
      path: '/recoupments'
    },
    {
      name: 'Distributors',
      path: '/distributors'
    },
    {
      name: 'Artists',
      path: '/artists'
    }
  ]
};
const ledgerRoutes = {
  id: 'ledger',
  name: 'Ledger',
  icon: IconLedger,
  path: '/payments',
  position: 'start',
  children: [
    {
      name: 'Payments',
      path: '/payments'
    },
    {
      name: 'Transactions',
      path: '/transactions'
    },
    {
      name: 'Statements',
      path: '/statements'
    },
    {
      name: 'Wallet',
      path: '/wallet'
    }
  ]
};
const adaRoutes = {
  id: 'ada',
  name: 'ADA Hub',
  icon: IconAda,
  path: '/ada',
  position: 'center'
};
const utilitiesRoutes = {
  id: 'utilities',
  name: 'Utilities',
  icon: IconUtilities,
  path: '/settings',
  position: 'start',
  children: [
    {
      name: 'Settings',
      path: '/settings'
    },
    {
      name: 'Import',
      path: '/import'
    },
    {
      name: 'Help',
      path: '/help'
    },
    {
      name: 'AdminToolkit',
      path: '/admin'
    }
  ]
};

const topLevelRoutes = [
  reportingRoutes,
  catalogRoutes,
  ledgerRoutes,
  adaRoutes,
  utilitiesRoutes
];

const checkNavAccess = (route) => {
  if (!route.meta.permissions) {
    return true;
  }
  return (
    permissions.filter((value) => route.meta.permissions.includes(value))
      .length > 0
  );
};

const showRoute = (routePath) => {
  const route = router.getRoutes().find((route) => route.path === routePath);
  if (!route) return false;
  const hasNavAccess = checkNavAccess(route);
  return hasNavAccess;
};

const environment = computed(() => {
  return store.environment;
});

const selectedMenuName = ref('');
const onNavMenuItemClick = (name) => (selectedMenuName.value = name);

const showBalance = computed(
  () => permissions.includes('account:ro') || permissions.includes('account:rw')
);
const balance = ref(0);
const getBalance = async () => {
  const data = await get('payment-method/balance');
  balance.value = data[0]?.total?.amount;
};

const isActive = (routeObj) => {
  return routeObj?.children?.some((child) => route?.path?.includes(child.path));
};

onMounted(() => {
  if (showBalance.value) {
    getBalance();
  } else {
    balance.value = null;
  }
});
</script>

<template>
  <section class="page-nav">
    <div class="env-flag" aria-hidden="true" v-if="environment !== 'prod'">
      {{ environment }}
    </div>
    <div class="page-nav__logo">
      <router-link to="/" title="Dashboard">
        <icon-r />
      </router-link>
    </div>
    <nav>
      <ul class="page-nav__items">
        <li
          v-for="route in topLevelRoutes"
          :key="route.id"
          :class="makeCssClass(route.name)"
        >
          <nav-menu-item
            v-if="showRoute(route.path)"
            :route="route"
            :on-menu-click="() => onNavMenuItemClick(route.name)"
            :active="isActive(route)"
          >
            <ul :class="{ 'page-nav__menu-items': route.children?.length }">
              <li v-for="r in route.children" :key="r.path">
                <nav-menu-link v-if="r.path && showRoute(r.path)" :to="r.path">
                  {{ $t(r.name, 2) }}
                </nav-menu-link>
              </li>
            </ul>
          </nav-menu-item>
        </li>
      </ul>
      <ul class="page-nav__items page-nav__items--lower">
        <li>
          <v-avatar>
            <avatar-menu :balance="balance" />
          </v-avatar>
        </li>
      </ul>
    </nav>
    <teleport to="#modal-content">
      <v-emulate-modal v-if="route.query.modal === 'emulate'"></v-emulate-modal>
    </teleport>
  </section>
</template>

<style lang="scss" scoped>
.page-nav {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-default);
  max-width: var(--width-side-nav);

  .env-flag {
    position: absolute;
    transform: rotate(-90deg);
    bottom: 72px;
    left: 0;
    right: 0;
    font-size: 84px;
    font-weight: var(--type-weight-bold);
    color: var(--color-warning-text-default);
    opacity: 0.35;
    text-transform: uppercase;
    pointer-events: none;
  }

  &__logo {
    padding: var(--spacing-large) var(--spacing-small) var(--spacing-medium);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 26px;
      height: auto;
    }
  }

  nav {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--spacing-large);
    padding-top: var(--spacing-small);
    padding-bottom: var(--spacing-large);
  }
  &__items {
    padding: 0 var(--spacing-medium);
    display: flex;
    flex-direction: column;
  }
  &__menu-items {
    padding-bottom: var(--spacing-medium);
  }
  li {
    > div {
      padding-bottom: var(--spacing-large);
    }
    &:last-child > div {
      padding-bottom: 0;
    }
  }
}
</style>
