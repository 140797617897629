<script setup>
import { computed, useSlots } from 'vue';
import { useStore } from '@/stores/main.store.js';
import { track } from '@/services/tracking.service';

const slots = useSlots();

const props = defineProps({
  to: {
    type: String,
    required: false
  },
  variant: {
    type: String,
    default: 'primary',
    validator: (val) =>
      ['primary', 'secondary', 'tertiary', 'plain'].includes(String(val))
  },
  size: {
    type: String,
    default: 'medium',
    validator: (val) =>
      ['small', 'medium', 'large', 'xlarge'].includes(String(val))
  },
  justify: {
    type: String,
    default: 'center',
    validator: (val) => ['start', 'center', 'end'].includes(String(val))
  }
});

const emit = defineEmits(['click']);
// Access the store via Pinia
const store = useStore();

// allow button to function as an anchor tag when needed
const type = computed(() => (props.to ? 'router-link' : 'button'));
const classes = computed(() =>
  [props.variant, props.size, props.justify]
    .map((item) => `v-button--${item}`)
    .join(' ')
);

const click = (event) => {
  event.stopPropagation();
  emit('click', event);
  track('buttonPress', {
    label: slots
      .default?.()
      ?.map((node) => node?.children || '')
      .join(' ')
      .trim(),
    type: type.value,
    to: props.to,
    variant: props.variant
  });
};
</script>

<template>
  <component
    :is="type"
    :to="to"
    class="v-button"
    :class="classes"
    :tabindex="store.pageFocus"
    @click="click"
  >
    <slot class="v-button__icon" name="startIcon"></slot>
    <div class="v-button__slot-container">
      <slot name="default"></slot>
    </div>
    <slot class="v-button__icon" name="endIcon"></slot>
  </component>
</template>

<style lang="scss" scoped>
.v-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: var(--radius-full);
  transition: all 0.2s ease;
  font-size: var(--type-medium-body);
  height: var(--height-button-medium);
  padding: var(--spacing-small) var(--spacing-medium);
  gap: var(--spacing-small);
  svg {
    height: var(--type-small-body);
    * {
      stroke: var(--color-icon-default);
    }
  }

  &:disabled {
    pointer-events: none;
    svg * {
      stroke: var(--color-icon-disabled);
    }
  }

  &__slot-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // JUSTIFICATION
  &--start {
    justify-content: start;
  }
  &--end {
    justify-content: end;
  }

  // SIZING
  &--xlarge {
    height: var(--height-button-xlarge);
    padding: var(--spacing-small) var(--spacing-xlarge);
  }
  &--large {
    height: var(--height-button-large);
  }
  &--small {
    height: var(--height-button-small);
    padding: var(--spacing-xsmall) var(--spacing-medium);
    gap: var(--spacing-xsmall);
    font-size: var(--type-small-body);
    .v-button__icon {
      height: var(--type-caption);
    }
  }

  // VARIANTS
  &--primary {
    box-shadow: 0 0 0 var(--border-stroke-strong)
      var(--color-primary-bg-default) inset;
    color: var(--color-text-strong);
    background-color: var(--color-primary-bg-default);
    &:hover {
      background-color: var(--color-primary-bg-hover);
      box-shadow: 0 0 0 var(--border-stroke-strong)
        var(--color-primary-bg-hover) inset;
    }
    &:focus-visible {
      box-shadow: 0 0 0 var(--border-stroke-strong)
        var(--color-secondary-border-default) inset;
    }
    &:active {
      background-color: var(--color-primary-bg-pressed);
      box-shadow: 0 0 0 var(--border-stroke-strong)
        var(--color-primary-bg-pressed) inset;
    }
    &:disabled {
      background-color: var(--color-primary-bg-disabled);
      box-shadow: 0 0 0 var(--border-stroke-strong)
        var(--color-primary-bg-disabled) inset;
      color: var(--color-text-disabled);
    }
  }
  &--secondary {
    box-shadow: 0 0 0 var(--border-stroke-default) var(--color-border-strong)
      inset;
    color: var(--color-text-strong);
    background-color: transparent;
    &:hover {
      background-color: var(--color-bg-strong-emphasis);
    }
    &:focus-visible {
      box-shadow: 0 0 0 var(--border-stroke-strong)
        var(--color-secondary-border-default) inset;
    }
    &:active {
      box-shadow: 0 0 0 var(--border-stroke-default) var(--color-border-pressed)
        inset;
    }
    &:disabled {
      box-shadow: 0 0 0 var(--border-stroke-default)
        var(--color-border-disabled) inset;
      color: var(--color-text-disabled);
    }
  }
  &--tertiary {
    padding: 0;
    height: auto;
    color: var(--color-text-strong);
    .v-button__slot-container {
      display: inline-block;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 95%;
        border-bottom: 1px solid var(--color-border-default);
      }
    }
    &:hover {
      .v-button__slot-container {
        &::after {
          border-bottom: 2px solid var(--color-border-strong);
        }
      }
      svg * {
        stroke: var(--color-icon-hover);
      }
    }
    &:focus-visible {
      .v-button__tertiary-border {
        height: var(--border-stroke-strong);
        background-color: var(--color-secondary-border-default);
      }
    }
    &:active {
      color: var(--color-text-pressed);
      .v-button__tertiary-border {
        height: var(--border-stroke-default);
        background-color: var(--color-border-pressed);
      }
      svg * {
        stroke: var(--color-icon-pressed);
      }
    }
    &:disabled {
      color: var(--color-text-disabled);
      .v-button__tertiary-border {
        background-color: var(--color-border-disabled);
      }
    }
  }
  &--plain {
    padding: 0;
    color: var(--color-text-strong);
    .v-button__tertiary-border {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      height: 0px;
      background-color: var(--color-border-default);
      transition: all 0.2s ease;
    }
    &:hover {
      .v-button__tertiary-border {
        height: var(--border-stroke-default);
        background-color: var(--color-border-strong);
      }
      svg * {
        stroke: var(--color-icon-hover);
      }
    }
    &:focus-visible {
      .v-button__tertiary-border {
        height: var(--border-stroke-default);
        background-color: var(--color-secondary-border-default);
      }
    }
    &:active {
      color: var(--color-text-pressed);
      .v-button__tertiary-border {
        height: var(--border-stroke-default);
        background-color: var(--color-border-pressed);
      }
      svg * {
        stroke: var(--color-icon-pressed);
      }
    }
    &:disabled {
      color: var(--color-text-disabled);
      .v-button__tertiary-border {
        background-color: var(--color-border-disabled);
      }
    }
  }
}
</style>
