<script>
import { DateTime } from 'luxon';

export default {
  name: 'VDateTimeColumn',
  props: {
    value: { type: Number, required: true }
  },
  computed: {
    date() {
      return DateTime(this.value * 1000).toFormat('L');
    },
    time() {
      return DateTime(this.value * 1000).toFormat('LT');
    }
  }
};
</script>

<template>
  <div class="v-date-time-column">
    <div class="date">{{ date }}</div>
    <div class="time">{{ time }}</div>
  </div>
</template>

<style lang="scss">
.v-date-time-column {
  user-select: none;
  display: flex;
  align-items: baseline;

  .date {
  }

  .time {
    margin-left: 5px;
    color: var(--hex_purple-text);
    font-size: 13px;
  }
}
</style>
