export default [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    name: 'ADA Hub',
    path: '/ada',
    redirect: 'https://login.partners.ada-music.com/',
    meta: {
      external: true,
      permissions: ['dashboard:ro', 'dashboard:rw']
    }
  },
  {
    name: 'Reporting',
    path: '/reporting',
    redirect: '/dashboard',
    meta: {
      permissions: [
        'dashboard:ro',
        'dashboard:rw',
        'analytics:ro',
        'analytics:rw'
      ]
    }
  },
  {
    name: 'Catalog',
    path: '/catalog',
    redirect: '/products',
    meta: {
      permissions: [
        'product:ro',
        'product:rw',
        'distributor:ro',
        'distributor:rw',
        'recoup:ro',
        'recoup:rw'
      ]
    }
  },
  {
    name: 'Ledger',
    path: '/ledger',
    redirect: '/payments',
    meta: {
      permissions: ['payment:ro', 'payment:rw', 'statement:ro', 'statement:rw']
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/login-page.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      globalFilters: []
    }
  },
  {
    path: '/reset',
    name: 'PasswordReset',
    component: () => import('@/pages/reset.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      globalFilters: []
    }
  },
  {
    path: '/claim',
    name: 'ClaimAccount',
    component: () => import('@/pages/claim.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      globalFilters: []
    }
  },
  {
    path: '/service/account',
    name: 'ActivateAccount',
    component: () => import('@/pages/activate.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      globalFilters: []
    }
  },
  {
    path: '/service/password',
    name: 'PasswordResetVerification',
    component: () => import('@/pages/password-verification.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      globalFilters: []
    }
  },
  {
    path: '/service/claim',
    name: 'AccountClaimVerification',
    component: () => import('@/pages/account-claim-verification.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      globalFilters: []
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/pages/dashboards/main.vue'),
    meta: {
      hasNav: true,
      navId: 'pagePrimary',
      permissions: ['dashboard:ro', 'dashboard:rw'],
      globalFilters: ['timeframe', 'dateselect', 'artist', 'User']
    }
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () => import('@/pages/analytics/main.vue'),
    meta: {
      hasNav: true,
      permissions: ['analytics:ro', 'analytics:rw'],
      globalFilters: ['timeframe', 'dateselect', 'artist']
    }
  },
  {
    path: '/statements',
    name: 'Statements',
    component: () => import('@/pages/statements/statements.vue'),
    meta: {
      hasNav: true,
      permissions: ['statement:ro', 'statement:rw'],
      globalFilters: []
    }
  },
  {
    path: '/statements/:id',
    name: 'Statement',
    component: () => import('@/pages/statements/statement.vue'),
    meta: {
      hasNav: true,
      permissions: ['statement:ro', 'statement:rw'],
      globalFilters: []
    }
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import('@/pages/payments/payments.vue'),
    meta: {
      hasNav: true,
      navId: 'pagePrimary',
      permissions: ['transaction:ro', 'transaction:rw'],
      globalFilters: []
    }
  },
  {
    path: '/payments/:id',
    name: 'Payment',
    component: () => import('@/pages/payments/payment.vue'),
    meta: {
      hasNav: true,
      permissions: ['transaction:ro', 'transaction:rw'],
      globalFilters: []
    }
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import('@/pages/transactions/transactions.vue'),
    meta: {
      hasNav: true,
      navId: 'pagePrimary',
      permissions: ['transaction:ro', 'transaction:rw'],
      globalFilters: []
    }
  },
  {
    path: '/transactions/:id',
    name: 'Transaction',
    component: () => import('@/pages/transactions/transaction.vue'),
    meta: {
      hasNav: true,
      permissions: ['transaction:ro', 'transaction:rw'],
      globalFilters: []
    }
  },
  {
    path: '/distributors',
    name: 'Distributors',
    component: () => import('@/pages/distributors/distributors.vue'),
    meta: {
      hasNav: true,
      permissions: ['distributor:ro', 'distributor:rw'],
      globalFilters: []
    }
  },
  {
    path: '/distributors/add',
    name: 'Add Distributor',
    component: () => import('@/pages/distributors/distributor-edit.vue'),
    meta: {
      permissions: ['distributor:rw'],
      hasNav: true,
      globalFilters: []
    }
  },
  {
    path: '/distributors/:id',
    name: 'Distributor',
    component: () => import('@/pages/distributors/distributor.vue'),
    meta: {
      hasNav: true,
      permissions: ['distributor:ro', 'distributor:rw'],
      globalFilters: []
    }
  },
  {
    path: '/distributors/:id/edit',
    name: 'Edit Distributor',
    component: () => import('@/pages/distributors/distributor-edit.vue'),
    meta: {
      permissions: ['distributor:rw'],
      hasNav: true,
      globalFilters: []
    }
  },
  {
    path: '/artists',
    name: 'Artists',
    component: () => import('@/pages/artists/artists.vue'),
    meta: {
      hasNav: true,
      permissions: ['artist:ro', 'artist:rw'],
      globalFilters: ['timeframe']
    }
  },
  {
    path: '/artists/:artistId',
    name: 'Artist',
    component: () => import('@/pages/artists/artist.vue'),
    meta: {
      hasNav: true,
      permissions: ['artist:ro', 'artist:rw'],
      globalFilters: ['timeframe']
    }
  },
  {
    path: '/products/add',
    name: 'Add Product',
    component: () => import('@/pages/products/product-add.vue'),
    meta: {
      permissions: ['product:rw'],
      hasNav: true,
      globalFilters: []
    }
  },
  {
    path: '/products/:productId',
    name: 'Product',
    component: () => import('@/pages/products/product.vue'),
    meta: {
      permissions: ['product:ro', 'product:rw'],
      hasNav: true,
      globalFilters: ['timeframe']
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/pages/products/products.vue'),
    meta: {
      hasNav: true,
      permissions: ['product:ro', 'product:rw'],
      globalFilters: ['timeframe', 'artist']
    }
  },
  {
    path: '/recoupments/add',
    name: 'Add Recoupment',
    component: () => import('@/pages/recoupments/recoupment-add.vue'),
    meta: {
      hasNav: true,
      permissions: ['recoup:rw'],
      globalFilters: []
    }
  },
  {
    path: '/recoupments/edit/:recoupmentId',
    name: 'Edit Recoupment',
    component: () => import('@/pages/recoupments/recoupment-add.vue'),
    meta: {
      hasNav: true,
      permissions: ['recoup:ro', 'recoup:rw'],
      globalFilters: []
    }
  },
  {
    path: '/recoupments/:recoupmentId',
    name: 'Recoupment',
    component: () => import('@/pages/recoupments/recoupment.vue'),
    meta: {
      hasNav: true,
      permissions: ['recoup:ro', 'recoup:rw'],
      globalFilters: ['timeframe']
    }
  },
  {
    path: '/recoupments',
    name: 'Recoupments',
    component: () => import('@/pages/recoupments/recoupments.vue'),
    meta: {
      hasNav: true,
      permissions: ['recoup:ro', 'recoup:rw'],
      globalFilters: ['timeframe', 'artist']
    }
  },
  {
    path: '/settings',
    name: 'Utilities',
    component: () => import('@/pages/settings/settings.vue'),
    meta: {
      hasNav: true,
      permissions: ['emulate:ro', 'emulate:rw', 'account:ro', 'account:rw'],
      globalFilters: []
    }
  },
  {
    path: '/import',
    name: 'Import',
    component: () => import('@/pages/import/import.vue'),
    meta: {
      hasNav: true,
      permissions: ['statement:rw', 'import:rw', 'import:ro'],
      globalFilters: []
    }
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import('@/pages/wallet/overview.vue'),
    meta: {
      hasNav: true,
      permissions: ['account:ro', 'account:rw'],
      globalFilters: []
    }
  },
  {
    path: '/admin',
    name: 'Admin Toolkit',
    component: () => import('@/pages/admin/main.vue'),
    meta: {
      hasNav: true,
      permissions: ['emulate:rw'],
      globalFilters: ['']
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('@/pages/help.vue'),
    meta: {
      hasNav: true,
      navId: 'pageSecondary',
      globalFilters: []
    }
  },
  {
    path: '/overseer',
    name: 'Overseer',
    component: () => import('@/pages/overseer.vue'),
    meta: {
      hasNav: true,
      permissions: ['emulate:rw', 'emulate:ro'],
      globalFilters: []
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404 Error',
    component: () => import('@/pages/404.vue'),
    meta: {
      fullPage: true,
      public: true,
      globalFilters: []
    }
  }
];
