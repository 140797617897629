<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { deepCopy, fnDebounce } from '@/utilities/index.util.js';
import { track } from '@/services/tracking.service';

// Props
const props = defineProps({
  name: { type: String, required: true },
  label: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  modelValue: { type: [Number, String], default: null },
  placeholder: { type: String, default: null },
  focus: { type: Boolean, default: false },
  small: { type: Boolean, default: false },
  debounce: { type: Number, default: 200 },
  onFocus: {
    type: Function,
    default: () => {
      return {};
    }
  },
  blur: {
    type: Function,
    default: () => {
      return {};
    }
  },
  onkeypress: { type: Function, default: () => true }
});

// Emits
const emit = defineEmits(['update:modelValue']);

// Local state
const localValue = ref(props.modelValue);

// Computed properties
const setValueFn = computed(() => {
  return fnDebounce((val) => {
    emit('update:modelValue', val);
  }, props.debounce);
});

// Watchers
watch(
  () => props.modelValue,
  (newVal) => {
    if (newVal !== localValue.value) {
      localValue.value = newVal;
    }
  },
  { immediate: false }
);

watch(
  localValue,
  (newVal, oldVal) => {
    if (oldVal !== undefined) {
      setValueFn.value(newVal);
    }
  },
  { immediate: false }
);

// Lifecycle hooks
onMounted(() => {
  if (props.focus) {
    const input = document.querySelector(`[ref="${props.name}-field"]`);
    if (input) input.focus();
  }
});

// Methods
const keyPress = (e) => {
  const val = e.target.value;
  if (props.onkeypress(val)) {
    localValue.value = e.target.value;
  } else {
    e.target.value = localValue.value;
  }
};

const localBlur = (e) => {
  props.blur(e);
};
</script>

<template>
  <label
    class="v-input v-filter-generic-input"
    :class="[
      {
        disabled: props.disabled,
        small: props.small
      }
    ]"
  >
    <svg
      v-if="!props.label"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5664 14.0117C14.7305 14.1758 14.7305 14.4766 14.5664 14.6406C14.4844 14.7227 14.375 14.75 14.2656 14.75C14.1289 14.75 14.0195 14.7227 13.9102 14.6406L10.0547 10.7578C9.04297 11.6328 7.75781 12.125 6.36328 12.125C3.24609 12.125 0.703125 9.58203 0.703125 6.4375C0.703125 3.32031 3.21875 0.75 6.36328 0.75C9.48047 0.75 12.0508 3.32031 12.0508 6.4375C12.0508 7.85938 11.5586 9.14453 10.6836 10.1562L14.5664 14.0117ZM6.39062 11.25C9.04297 11.25 11.2031 9.11719 11.2031 6.4375C11.2031 3.78516 9.04297 1.625 6.39062 1.625C3.71094 1.625 1.57812 3.78516 1.57812 6.4375C1.57812 9.08984 3.71094 11.25 6.39062 11.25Z"
        fill="white"
      />
    </svg>
    <div class="label" v-else>{{ props.label }}:</div>

    <input
      :ref="`${props.name}-field`"
      v-bind="props.inputProps"
      :name="props.name"
      :id="props.name"
      :value="localValue"
      :disabled="props.disabled"
      :placeholder="props.placeholder"
      class="input"
      @focus="props.onFocus"
      @input="keyPress"
      @blur="localBlur"
    />
  </label>
</template>

<style lang="scss">
.v-filter-generic-input {
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
  width: auto;
  font-size: var(--type-small-body);
  padding: 0 0 0 var(--spacing-medium);
  border-radius: var(--radius-xsmall);
  height: var(--height-button-xlarge);
  background-color: var(--color-bg-default-emphasis);
  border: var(--border-stroke-default) solid var(--color-border-subtle-emphasis);
  color: var(--color-text-strong);
  cursor: text;

  &.small {
    height: var(--height-button-large);
  }

  &:disabled {
    border-color: transparent;
    background-color: var(--color-bg-default-emphasis);
    color: var(--color-text-disabled);
    &::placeholder {
      color: var(--color-text-disabled);
    }
  }

  &:hover,
  &:has(input:focus) {
    border-color: var(--color-border-stronger-emphasis);
    color: var(--color-text-strong);
    background-color: var(--color-bg-strong-emphasis);
    &::placeholder {
      color: var(--color-text-strong);
    }
  }

  &:has(input:focus) {
    border-color: var(--color-secondary-border-pressed);
    box-shadow: 0 0 0 var(--border-stroke-default)
      var(--color-secondary-border-pressed);
    background: var(--color-bg-strong-emphasis);
  }

  &.disabled {
    pointer-events: none;
  }

  .label {
    white-space: nowrap;
    color: var(--color-text-strong);
    font-weight: var(--type-weight-bold);
  }

  .input {
    height: 100%;
    background: transparent;
    border: none;
    width: 100%;
    &::placeholder {
      color: var(--color-text-subtle);
    }
  }
}
</style>
