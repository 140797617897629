<script setup>
import IconX from '../icons/icon-x.vue';
import IconButton from './icon-button.vue';

const emit = defineEmits(['click']);
// emit close event
const click = (event) => {
  emit('click', event);
};

const props = defineProps({
  isPage: {
    type: Boolean,
    default: false
  }
});
</script>

<template>
  <div class="v-modal-header">
    <slot></slot>
    <IconButton @click="click" v-if="!isPage">
      <IconX />
    </IconButton>
  </div>
</template>

<style lang="scss" scoped>
.v-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-xlarge);
  padding-bottom: 0;
  font-size: var(--type-medium-headline);
  font-weight: var(--type-weight-semibold);
}
</style>
