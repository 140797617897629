<script setup>
import { ref, computed } from 'vue';
import NavItem from './nav-item.vue';
import NavMenu from './nav-menu.vue';
import { useRouter } from 'vue-router';
import VPopover from '@/components/elements/v-popover.vue';

const props = defineProps({
  route: {
    type: Object
  },
  onMenuClick: {
    type: Function
  },
  active: {
    type: Boolean,
    default: false
  }
});

const showMenu = ref(false);

const isActive = computed(() => {
  return props.active || showMenu.value;
});
const router = useRouter();
const onClickNavMenuItem = () => {
  if (!props.route?.redirect) {
    router.push(props.route?.path);
  }
};
</script>

<template>
  <v-popover :alignment="route.position">
    <template #trigger="{ show }">
      <nav-item
        @click="onClickNavMenuItem"
        @mouseenter="show"
        @focus="show"
        :active="isActive"
      >
        <component :is="route.icon" />
      </nav-item>
    </template>
    <template #default="{ hide }">
      <nav-menu :title="route.name" @mouseleave="hide" @blur="hide">
        <slot></slot>
      </nav-menu>
    </template>
  </v-popover>
</template>
