import { onMounted, onBeforeUnmount } from 'vue';

export function useEventListener(target, event, handler) {
  onMounted(() => {
    target.addEventListener(event, handler);
  });
  // clean it up
  onBeforeUnmount(() => {
    target.removeEventListener(event, handler);
  });
}
