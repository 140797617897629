<template>
  <div class="v-modal-body">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.v-modal-body {
  padding: var(--spacing-medium) var(--spacing-xlarge);
  // TODO: add max-height and overflow
  // waiting until a proper popover component is created
  // max-height: 60vh;
  // overflow: auto;
}
</style>
