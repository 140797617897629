<script>
import VHeaderList from '@/components/list/header.list.vue';
import VRowList from '@/components/list/row.list.vue';
import { useStore } from '@/stores/main.store.js';
import VButton from '@/components/elements/button.vue';

export default {
  name: 'VList',
  components: {
    VRowList,
    VHeaderList,
    VButton
  },
  props: {
    id: { type: String, default: 'all' },
    rows: { type: Array, default: () => [] },
    limit: { type: Number, default: null },
    action: { type: Function }, // Row Action
    def: { type: Array, required: true },
    lock: { type: [String, Boolean], default: false }
  },
  data: () => {
    return {
      expanded: false,
      store: useStore()
    };
  },
  computed: {
    expandable() {
      return this.limit && this.limit < this.rows.length;
    },
    computedRows() {
      if (this.expandable && !this.expanded) {
        return this.rows.slice(0, this.limit);
      }

      return this.rows;
    }
  },
  watch: {
    $props: {
      handler() {
        this.expanded = !this.expandable;
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.expanded = !this.expandable;
  },
  methods: {
    expand() {
      this.expanded = true;
    }
  }
};
</script>

<template>
  <div class="v-list">
    <v-header-list :def="def" :rows="rows" />
    <div class="rows">
      <v-row-list
        v-for="(row, i) in computedRows"
        :key="row.id"
        :def="def"
        :row="row"
        :rows="rows"
        :lock="lock"
        :interactive="typeof action === 'function'"
        @click="action?.(row)"
      >
      </v-row-list>
    </div>
    <div v-if="!expanded" class="expand">
      <v-button variant="tertiary" @click="expand">
        {{ $t('ShowAll') }} {{ rows.length }} {{ this.$t('Items', 2) }}
      </v-button>
    </div>
  </div>
</template>

<style lang="scss">
.v-list {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1 1 100%;
  flex-direction: column;
  box-sizing: border-box;
  overflow-x: hidden;
  gap: var(--spacing-small);

  .rows {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    gap: var(--spacing-small);
    overflow: hidden;
  }

  .v-header-list,
  .v-list-row {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }

  .expand {
    padding: var(--spacing-small) 0 var(--spacing-small);
  }

  .v-list-row {
    &:nth-child(1) .color-box,
    &:nth-child(18n + 1) .color-box {
      background: var(--color-chart-green1);
    }

    &:nth-child(2) .color-box,
    &:nth-child(18n + 2) .color-box {
      background: var(--color-chart-purple1);
    }

    &:nth-child(3) .color-box,
    &:nth-child(18n + 3) .color-box {
      background: var(--color-chart-red1);
    }

    &:nth-child(4) .color-box,
    &:nth-child(18n + 4) .color-box {
      background: var(--color-chart-blue1);
    }

    &:nth-child(5) .color-box,
    &:nth-child(18n + 5) .color-box {
      background: var(--color-chart-purple2);
    }

    &:nth-child(6) .color-box,
    &:nth-child(18n + 6) .color-box {
      background: var(--color-chart-green2);
    }

    &:nth-child(7) .color-box,
    &:nth-child(18n + 7) .color-box {
      background: var(--color-chart-red2);
    }

    &:nth-child(8) .color-box,
    &:nth-child(18n + 8) .color-box {
      background: var(--color-chart-yellow1);
    }

    &:nth-child(9) .color-box,
    &:nth-child(18n + 9) .color-box {
      background: var(--color-chart-blue4);
    }

    &:nth-child(10) .color-box,
    &:nth-child(18n + 10) .color-box {
      background: var(--color-chart-orange1);
    }

    &:nth-child(11) .color-box,
    &:nth-child(18n + 11) .color-box {
      background: var(--color-chart-green3);
    }

    &:nth-child(12) .color-box,
    &:nth-child(18n + 12) .color-box {
      background: var(--color-chart-pink1);
    }

    &:nth-child(13) .color-box,
    &:nth-child(18n + 13) .color-box {
      background: var(--color-chart-gray1);
    }

    &:nth-child(14) .color-box,
    &:nth-child(18n + 14) .color-box {
      background: var(--color-chart-red3);
    }

    &:nth-child(15) .color-box,
    &:nth-child(18n + 15) .color-box {
      background: var(--color-chart-yellow2);
    }

    &:nth-child(16) .color-box,
    &:nth-child(18n + 16) .color-box {
      background: var(--color-chart-blue5);
    }

    &:nth-child(17) .color-box,
    &:nth-child(18n + 17) .color-box {
      background: var(--color-chart-gray2);
    }

    &:nth-child(18) .color-box,
    &:nth-child(18n + 18) .color-box {
      background: var(--color-chart-pink2);
    }
  }

  .column {
    width: 0;
    min-width: 0;
    flex: 1 1 auto;
    margin-left: var(--spacing-medium);

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
