<script>
import IconCheckmark from '@/components/icons/icon-checkmark.vue';

export default {
  name: 'VManagerColumn',
  components: {
    IconCheckmark
  },
  props: {
    value: { type: String, required: true }
  },
  computed: {
    showIcon() {
      return this.value === 'warranter';
    }
  }
};
</script>

<template>
  <div class="v-manager-column">
    <IconCheckmark v-if="showIcon" name="checkmark" />
  </div>
</template>

<style lang="scss">
.v-manager-column {
  user-select: none;

  .v-icon {
    fill: var(--hex_success);
  }
}
</style>
