import * as amplitude from '@amplitude/analytics-browser';
import router from '@/router';

const toSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const convertToSnakeCase = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [toSnakeCase(key), value])
  );
};

export function track(type = 'pageView', props = {}) {
  const event = {
    event_type: type,
    application: 'payfi',
    event_properties: {
      ...convertToSnakeCase(props)
    }
  };
  if (type !== 'pageView') {
    event.event_properties.page_uri = router.currentRoute.value.path;
  }
  amplitude.track(event);
}

export function trackUser(user) {
  amplitude.setUserId(user.username);
  amplitude.setGroup('roles', user.roles);
  amplitude.setGroup('emulated', !!user.emulation);
  amplitude.identify(
    new amplitude.Identify()
      .set('username', user.username)
      .set('name', user.name)
      .set('email', user.username)
  );
}
