<script>
export default {
  name: 'VColumnUser',
  props: {
    value: { type: String, default: null }
  }
};
</script>

<template>
  <div class="v-column-user">
    {{ value }}
  </div>
</template>

<style lang="scss">
.v-column-user {
}
</style>
