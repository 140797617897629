<script>
export default {
  name: 'VNameColumn',
  props: {
    value: { type: Object, required: true } // {firstName: str, middleInitial: str, lastName: str}
  },
  computed: {
    name() {
      const name = this.value;
      return name.middleInitial.length
        ? `${name.firstName} ${name.middleInitial}. ${name.lastName}`
        : `${name.firstName} ${name.lastName}`;
    }
  }
};
</script>

<template>
  <div class="v-name-column">
    {{ name }}
  </div>
</template>

<style lang="scss">
.v-name-column {
  user-select: text;
  color: var(--hex_table-column-standard-text);
  font-size: 15px;
}
</style>
