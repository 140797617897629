import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import { extendSession } from '@/services/api.service.js';
import { track } from '@/services/tracking.service.js';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  checkAuth(to, from, next);
});

function checkAuth(to, from, next) {
  if (to.path.includes('http')) {
    to.path = to.path.replace('/http', 'http');
    window.open(to.path, '_blank');
    return;
  }

  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    (record) => record.meta.onlyWhenLoggedOut
  );

  const user = JSON.parse(localStorage.getItem('userPrefs'));
  track('pageView', {
    path: to.path,
    name: to.name,
    query: to.query
  });

  if (isPublic) {
    next();
  } else {
    extendSession().then((loggedIn) => {
      if (!loggedIn) {
        return next({
          path: '/login',
          query: { redirect: to.fullPath }
        });
      }

      if (loggedIn && onlyWhenLoggedOut) {
        return next('/');
      }

      if (to.meta.permissions) {
        const hasPermission =
          user.permissions.filter((permission) =>
            to.meta.permissions.includes(permission)
          ).length > 0;

        if (!hasPermission) {
          let path;
          switch (true) {
            case user.permissions.includes('statement:rw'):
              path = '/statements';
              break;
            case user.permissions.includes('emulate:rw'):
              path = '/admin';
              break;
            case user.permissions.includes('emulate:ro'):
              path = '/overseer';
              break;
            default:
              path = '/dashboard';
          }

          return next({
            path: path
          });
        }
      }

      next();
    });
  }
}

export default router;
