import { useEventListener } from './use-event-listener';

export function useOnClickOutside(rootEl, callback) {
  // `mousedown` or `mouseup` here makes it easier to not trigger the callback immedialty
  // if you want to use `click` you need to call `stopPropagation` on the trigger element.
  useEventListener(window, 'mouseup', (e) => {
    const clickedEl = e.target;
    // skip if the root element contains the clicked element
    if (rootEl.value?.contains(clickedEl)) {
      return;
    }

    // otherwise execute the action
    callback();
  });
}
