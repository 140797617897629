<script setup>
import { computed, useSlots } from 'vue';
import VPopover from './v-popover.vue';
import IconQuestion from '../icons/icon-question.vue';
const { size } = defineProps({
  size: {
    type: String,
    default: 'large',
    validator: (value) => ['small', 'large'].includes(value)
  },
  side: {
    type: String,
    default: 'right',
    validator: (value) => ['left', 'right', 'top', 'bottom'].includes(value)
  },
  alignment: {
    type: String,
    default: 'center',
    validator: (value) => ['start', 'center', 'end'].includes(value)
  }
});

const slots = useSlots();
const hasTooltip = computed(() => {
  return slots.default?.().some((item) => {
    if (typeof item.children === 'string') {
      return item.children.trim();
    }
    return item.children?.length;
  });
});

const contentClasses = computed(() =>
  [size].map((item) => `v-tooltip__content--${item}`)
);
</script>

<template>
  <div class="v-tooltip">
    <v-popover :side="side" :alignment="alignment">
      <template #trigger="{ show, hide }">
        <div
          class="v-tooltip__trigger"
          @mouseenter="hasTooltip ? show() : undefined"
          @mouseleave="hasTooltip ? hide() : undefined"
          @focus="hasTooltip ? show() : undefined"
          @blur="hasTooltip ? hide() : undefined"
          :tabindex="hasTooltip ? 0 : undefined"
        >
          <slot name="trigger"></slot>
          <IconQuestion v-if="hasTooltip" />
        </div>
      </template>
      <template v-if="hasTooltip" #default="{ side }">
        <div
          class="v-tooltip__content"
          :class="[...contentClasses, `v-tooltip__content--${side}`]"
        >
          <slot></slot>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<style lang="scss" scoped>
.v-tooltip {
  &__trigger {
    display: inline-flex;
    gap: var(--spacing-xsmall);
    align-items: baseline;
    border-radius: var(--radius-xsmall);
    svg {
      width: 12px;
      height: 12px;
      fill: var(--color-icon-disabled);
    }
    &:hover {
      svg {
        fill: var(--color-icon-default);
      }
    }
    &:focus-visible {
      outline-style: solid;
      outline-width: var(--border-stroke-strong);
      outline-color: var(--color-secondary-border-default);
      outline-offset: var(--spacing-2xsmall);
      svg {
        fill: var(--color-icon-default);
      }
    }
  }
  &__content {
    white-space: normal;
    background: var(--color-bg-level-01);
    border: var(--border-stroke-default) solid
      var(--color-border-strong-emphasis);
    padding: var(--spacing-small) var(--spacing-medium);
    border-radius: var(--spacing-xsmall);
    color: var(--color-text-strong-on-dark);
    max-width: 400px;

    &--small {
      padding: var(--spacing-xsmall) var(--spacing-small);
    }

    &--left,
    &--right {
      margin: 0 var(--spacing-small);
    }
    &--top,
    &--bottom {
      margin: var(--spacing-small) 0;
    }
  }
}
</style>
