<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    width="17"
    height="17"
    fill="currentColor"
    class="icon"
  >
    <g clip-path="url(#clip0_6050_3332)">
      <path
        d="M7.549 10.74h1.867c.005-.375.057-.7.154-.973.103-.274.293-.535.572-.784.322-.303.617-.596.886-.879a3.88 3.88 0 00.652-.916c.166-.332.249-.71.249-1.135 0-.61-.13-1.128-.389-1.553a2.435 2.435 0 00-1.12-.974c-.488-.224-1.074-.337-1.758-.337-.62 0-1.18.108-1.677.323-.498.21-.896.534-1.194.974-.293.434-.447.986-.461 1.655h2.124c.005-.303.063-.547.175-.732.118-.186.267-.323.447-.41.18-.093.376-.14.586-.14.254 0 .464.05.63.147.17.098.298.242.38.432.089.186.133.42.133.703a1.5 1.5 0 01-.147.667 2.496 2.496 0 01-.403.57 8.26 8.26 0 01-.6.587 4.06 4.06 0 00-.66.769 2.69 2.69 0 00-.344.871 6.223 6.223 0 00-.102 1.136zm.102 1.487a1.028 1.028 0 00-.322.777c0 .303.108.559.322.769.215.21.503.315.865.315.356 0 .642-.105.857-.315.22-.21.33-.466.33-.77 0-.312-.11-.57-.33-.776-.215-.21-.5-.314-.857-.314-.362 0-.65.105-.865.314z"
      />
      <path
        fill-rule="evenodd"
        d="M17 8.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zm-1 0a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
        clip-rule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_6050_3332"><path d="M0 0h17v17H0z" /></clipPath>
    </defs>
  </svg>
</template>
