<script setup>
import { computed } from 'vue';
const props = defineProps({
  icon: { type: String, default: null },
  size: {
    type: String,
    default: 'medium',
    validator: (val) => {
      return ['small', 'medium', 'large', 'xlarge'].includes(String(val));
    }
  }
});
const emit = defineEmits(['click']);
const classes = computed(() => `v-icon-button--${props.size}`);
const click = (event) => {
  emit('click', event);
};
</script>

<template>
  <button @click="click" class="v-icon-button" :class="classes">
    <div class="v-icon-button__icon">
      <slot></slot>
    </div>
  </button>
</template>

<style lang="scss">
.v-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: var(--radius-full);
  transition: all 0.2s ease;
  aspect-ratio: 1 / 1;
  height: var(--height-button-medium);
  padding: var(--spacing-small);
  background-color: transparent;
  border: none;
  &__icon {
    display: flex;
    place-items: center;
    > svg {
      fill: var(--color-icon-strong);
      height: var(--type-medium-body);
    }
  }
  // SIZING
  &--xlarge {
    padding: var(--spacing-medium);
    height: var(--height-button-xlarge);
    .v-icon-button__icon {
      height: var(--type-small-headline);
    }
  }
  &--large {
    padding: var(--spacing-small);
    height: var(--height-button-large);
  }
  &--small {
    padding: var(--spacing-xsmall);
    height: var(--height-button-small);
    .v-icon-button__icon {
      height: var(--type-caption);
    }
  }
  &:hover {
    background-color: var(--color-bg-strong-emphasis);
  }
  &:focus-visible {
    box-shadow: 0 0 0 var(--border-stroke-strong)
      var(--color-secondary-border-default) inset;
  }
  &:active {
    background-color: var(--color-primary-bg-default);
  }
  &:disabled {
    .v-icon-button__icon svg * {
      fill: var(--color-icon-disabled);
    }
    pointer-events: none;
  }
}
</style>
