<script setup>
import { computed } from 'vue';

const props = defineProps({
  to: {
    type: String,
    required: false
  },
  active: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});
const type = computed(() => (props.to ? 'router-link' : 'button'));
</script>

<template>
  <component :is="type" :to="to" class="nav-item" :class="{ disabled, active }">
    <span class="nav-item__child">
      <slot></slot>
    </span>
  </component>
</template>

<style lang="scss">
.nav-item {
  border-radius: var(--radius-xsmall);
  padding: var(--spacing-small);
  height: var(--height-button-large);
  aspect-ratio: 1 / 1;
  cursor: pointer;
  display: block;
  .ada-hub & {
    padding: var(--spacing-small) var(--spacing-2xsmall);
  }

  .nav-item__child {
    svg {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      max-height: var(--type-small-display);
      max-width: var(--type-small-display);
      .ada-hub & {
        max-width: 36px;
        width: 36px;
      }
      * {
        fill: var(--color-icon-default);
      }
    }
  }

  &:hover {
    background-color: var(--color-bg-level-05);
  }
  &:focus-visible {
    box-shadow: 0 0 0 var(--border-stroke-strong)
      var(--color-secondary-border-default) inset;
  }
  &:active {
    background-color: var(--color-primary-bg-default);
  }
  &.disabled {
    pointer-events: none;
    .nav-item__child svg * {
      fill: var(--color-icon-disabled);
    }
  }
  &.active {
    background-color: var(--color-primary-bg-default);
  }
}
</style>
