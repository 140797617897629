<script setup>
import { useStore } from '@/stores/main.store.js';

// Props
const props = defineProps({
  title: {
    type: String,
    default: 'info',
    validator: (value) => {
      const condition = value.length < 80;
      if (!condition) {
        console.error(
          'V-Message: Title - Passed prop is either not a string or is too long'
        );
      }
      return condition;
    }
  },
  message: { type: String, default: null },
  type: {
    type: String,
    default: 'info',
    validator: (value) => {
      const condition =
        value === 'info' ||
        value === 'success' ||
        value === 'warning' ||
        value === 'error';
      if (!condition) {
        console.error(
          'V-Message: Type - Pass prop must be "info", "success", "warning", or "error"'
        );
      }
      return condition;
    }
  }
});

// Component setup
const mainStore = useStore();

function hideMessage() {
  mainStore.toggleGlobalMessage(false);
}
</script>

<template>
  <div class="v-message" :class="[type]" role="banner">
    <div class="title">
      {{ title }}
    </div>
    <div class="message">
      {{ message }}
    </div>
    <button class="close" @click="hideMessage">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-x"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.v-message {
  display: flex;
  position: relative;
  z-index: 2;
  height: 10px * 7;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--hex_message-text);
  text-align: center;

  &.info {
    background-color: rgba(var(--rgb_info), 0.75);
  }

  &.success {
    background-color: rgba(var(--rgb_success), 0.75);
  }

  &.warning {
    background-color: rgba(var(--rgb_warning), 0.75);
  }

  &.error {
    background-color: rgba(var(--rgb_error), 0.75);
  }

  .title {
    font-size: 24px;
    font-weight: 300;
    line-height: 26px;
  }

  .message {
    font-size: 14px;
    line-height: 18px;
  }

  .close {
    position: absolute;
    right: 10px * 2;

    svg {
      transition: transform 0.15s ease;
    }

    &:hover {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
