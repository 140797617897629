<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    width="17"
    height="17"
    fill="currentColor"
    class="icon"
  >
    <path
      d="M16.845 3.155a.528.528 0 00-.746 0L6.5 12.754l-5.599-5.6a.528.528 0 10-.746.747L6.5 14.246 16.845 3.901a.528.528 0 000-.746z"
    />
  </svg>
</template>
