<script setup>
import { computed, onMounted } from 'vue';
import IconX from '@/components/icons/icon-x-thick.vue';
import IconCheckCircle from '@/components/icons/icon-check-circle.vue';
import IconInfoCircle from '@/components/icons/icon-info-circle.vue';
import IconExclamationTriangle from '@/components/icons/icon-exclamation-triangle.vue';
import IconExclamationCircle from '@/components/icons/icon-exclamation-circle.vue';
import { sentenceCase } from '@/utilities/string.util';

const props = defineProps({
  toast: {
    type: Object,
    required: true,
    default: () => ({
      id: 0,
      type: 'info',
      title: '',
      content: '',
      timer: 0,
      cancelable: false
    })
  },
  closeToast: Function
});

const icon = computed(
  () =>
    ({
      info: IconInfoCircle,
      success: IconCheckCircle,
      warning: IconExclamationTriangle,
      error: IconExclamationCircle
    })[props.toast.type] || IconCheckCircle
);

const progressTransition = () => {
  if (!props.toast.timer) return {};
  return {
    'animation-duration': `${props.toast.timer}s`
  };
};

const timedClose = () => {
  if (props.toast.timer) {
    setTimeout(() => {
      props.closeToast(props.toast.id);
    }, props.toast.timer * 1000);
  }
};
onMounted(() => {
  timedClose();
});
</script>

<template>
  <div class="toast" :class="['--' + toast.type]">
    <div v-if="toast.title || toast.cancelable" class="header">
      <component :is="icon" class="toast__icon" />
      <h2 v-if="toast.title">
        {{ toast.title }}
      </h2>
      <div class="spacer"></div>
      <button
        v-if="toast.cancelable"
        @click="closeToast(toast.id)"
        class="toast__close-btn"
      >
        <IconX />
      </button>
    </div>
    <div class="content">
      {{ sentenceCase(toast.content) }}
    </div>
    <div
      v-if="toast.timer"
      class="progress-bar pin"
      :style="progressTransition()"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
.toast {
  $icon-width: 14px;
  position: relative;
  width: 100%;
  padding: var(--spacing-small);
  border-radius: var(--radius-xsmall);
  color: var(--color-text-strong-on-light);
  border: var(--border-stroke-default) solid;
  border-color: var(--color-info-border-default);
  background-color: var(--color-info-bg-default);

  &__icon {
    height: $icon-width;
    width: $icon-width;
  }

  &__close-btn {
    display: grid;
    place-items: center;
    svg {
      height: $icon-width;
      width: 9px;
    }
  }

  .header {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--spacing-small);
    h2 {
      font-size: var(--type-small-body);
      font-weight: var(--type-weight-semibold);
    }
    .spacer {
      flex-grow: 1;
    }
  }

  .content {
    padding-left: calc($icon-width + var(--spacing-small));
    font-size: var(--type-caption);
  }

  .progress-bar {
    opacity: 0.5;
    height: var(--spacing-xsmall);
    width: 100%;
    pointer-events: none;
    background-color: var(--color-info-border-default);
    transform-origin: left top;
    animation: scaleWidth linear;
  }

  // VARIANTS
  &.--error {
    border-color: var(--color-error-border-default);
    background-color: var(--color-error-bg-default);
    .progress {
      background-color: var(--color-error-border-default);
    }
  }

  &.--warning {
    border-color: var(--color-warning-border-default);
    background-color: var(--color-warning-bg-default);
    .progress {
      background-color: var(--color-warning-border-default);
    }
  }

  &.--success {
    border-color: var(--color-success-border-default);
    background-color: var(--color-success-bg-default);
    .progress {
      background-color: var(--color-success-border-default);
    }
  }

  @keyframes scaleWidth {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }
}
</style>
