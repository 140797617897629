<script>
import { makeCssClass } from '@/utilities/index.util.js';

export default {
  name: 'VHeaderList',
  components: {},
  props: {
    def: { type: Array, default: null },
    rows: { type: Array, default: null }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    makeCssClass(str) {
      return makeCssClass(str);
    },
    colStyle(column) {
      return {
        flex: `${column.flexGrow} ${column.flexGrow} ${column.width}px`,
        ...(column?.textAlign ? {textAlign: column.textAlign} : {})
      };
    },
    isShow(key, condition) {
      if (condition) {
        if (typeof condition === 'function') {
          if (this.rows) {
            for (let i = 0; i < this.rows.length; i++) {
              if (condition(this.rows[i][key])) {
                return true;
              }
            }
          }

          return false;
        } else if (condition === 'hide-null') {
          if (this.rows) {
            for (let i = 0; i < this.rows.length; i++) {
              if (this.rows[i][key]) {
                return true;
              }
            }
          }

          return false;
        } else {
          console.warn(
            'unsupported show argument - supports; hide-null, function'
          );
        }
      }

      return true;
    }
  }
};
</script>

<template>
  <div class="v-header-list row">
    <div
      v-for="(column, i) in def"
      v-show="isShow(column.key, column.show) !== false"
      :key="i"
      :class="[
        'column',
        'header-column',
        'ellipsis',
        `key-${makeCssClass(column.key)}`
      ]"
      :style="colStyle(column)"
    >
      {{ column.label ? column.label.toString() : column.label }}
    </div>
  </div>
</template>

<style lang="scss">
.v-header-list {
  padding: 0 var(--spacing-medium);
  .header-column {
    position: relative;
    height: 100%;
    color: var(--color-text-subtle);
    font-size: var(--type-small-body);
  }
}
</style>
