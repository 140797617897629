<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    width="17"
    height="17"
    fill="currentColor"
    class="icon"
  >
    <path
      d="M14.845 2.155a.528.528 0 00-.746 0L8.5 7.753 2.901 2.155a.528.528 0 10-.746.746L7.753 8.5l-5.598 5.599a.528.528 0 10.746.746L8.5 9.247l5.599 5.598a.528.528 0 00.746-.746L9.247 8.5l5.598-5.599a.528.528 0 000-.746z"
    />
  </svg>
</template>
