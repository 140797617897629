<script>
import VStandardColumn from '@/components/table/columns/standard.column.vue';
import VRoleColumn from '@/components/table/columns/role.column.vue';
import VNameColumn from '@/components/table/columns/name.column.vue';
import VUserColumn from '@/components/table/columns/user.column.vue';
import VPercentColumn from '@/components/table/columns/percent.column.vue';
import VManagerColumn from '@/components/table/columns/manager.column.vue';
import VDateTimeColumn from '@/components/table/columns/dateTime.column.vue';
import VDateColumn from '@/components/table/columns/date.column.vue';
import VActionColumn from '@/components/table/columns/action.column.vue';
import VLabelValue from '@/components/elements/label-value.vue';
import { makeCssClass, makeValueClass } from '@/utilities/index.util.js';
import { useStore } from '@/stores/main.store.js';

export default {
  name: 'VRowList',
  components: {
    VStandardColumn,
    VUserColumn,
    VNameColumn,
    VPercentColumn,
    VManagerColumn,
    VDateTimeColumn,
    VDateColumn,
    VRoleColumn,
    VActionColumn,
    VLabelValue
  },
  props: {
    row: { type: Object, default: null },
    rows: { type: Array, default: null }, // Required when used with 'show' feature
    action: { type: Function, default: () => {} }, // Row Action
    def: { type: Array, required: true },
    lock: { type: [String, Boolean], default: false },
    interactive: { type: Boolean, default: false }
  },
  data() {
    return {
      store: useStore()
    };
  },
  methods: {
    makeCssClass(str) {
      return makeCssClass(str);
    },
    makeValueClass(str) {
      return makeValueClass(str);
    },
    colStyle(column) {
      return {
        flex: `${column.flexGrow} ${column.flexGrow} ${column.width}px`,
        ...(column?.textAlign ? { textAlign: column.textAlign } : {})
      };
    },
    isShow(key, condition) {
      if (condition) {
        if (typeof condition === 'function') {
          if (this.rows) {
            for (let i = 0; i < this.rows.length; i++) {
              if (condition(this.rows[i][key])) {
                return true;
              }
            }
          }

          return false;
        } else if (condition === 'hide-null') {
          if (this.rows) {
            for (let i = 0; i < this.rows.length; i++) {
              if (this.rows[i][key]) {
                return true;
              }
            }

            return false;
          } else {
            console.warn('IsShow: Show feature requires rows argument');
          }
        }
      }

      return true;
    }
  }
};
</script>

<template>
  <div class="v-list-row row" :class="{ interactive }">
    <component
      :is="column.component"
      v-for="(column, i) in def"
      v-show="isShow(column.key, column.show)"
      :key="i"
      :class="[
        'column',
        'ellipsis',
        makeCssClass(column.key),
        makeValueClass(
          column.key
            .split('.')
            .reduce((o, i) => (o && i && o[i] ? o[i] : null), row)
        )
      ]"
      :style="colStyle(column)"
      :value="
        column.key
          .split('.')
          .reduce((o, i) => (o && i && o[i] ? o[i] : null), row)
      "
      :row="row"
      :link-key="
        column.linkKey
          ? column.linkKey.split('.').reduce((o, i) => o[i], row)
          : ''
      "
      :link-path="column.linkPath"
      :format="column.format"
      :action="column.action"
      :show-action="
        column.showKey
          ? column.showKey.split('.').reduce((o, i) => o[i], row)
          : true
      "
      :btn-label="column.btnLabel"
      :hidden="column.hidden"
      :disabled="column.disabled"
      :lock="lock"

    ></component>
  </div>
</template>

<style lang="scss">
.v-list-row {
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  padding: var(--spacing-medium);
  border-radius: var(--radius-small);
  &:nth-child(odd) {
    background-color: var(--color-bg-default-emphasis);
  }
  &.interactive:hover {
    cursor: pointer;
    background-color: var(--color-bg-default-emphasis);
    box-shadow: 0 0 0 var(--border-stroke-default) var(--color-border-hover)
      inset;
  }
}
</style>
