import countriesQuery from 'countries-code';
import i18n from '@/i18n';
import { DateTime } from 'luxon';
import { useStore } from '@/stores/main.store';

export function makeCssClass(str) {
  return str.replace(' ', '-').replace('.', '-').toLowerCase();
}

export function makeValueClass(str) {
  if (str) {
    var val;

    if (str.length > 16 || /\s/.test(str)) {
      // eslint-disable-next-line
      val = str
        .split('')
        .map((v) => v.charCodeAt(0))
        .reduce((a, v) => (a + ((a << 7) + (a << 3))) ^ v)
        .toString(16)
        .replace(/^-?/, 'h');
    } else {
      val = 'val-' + String(str);
    }

    return val.toLowerCase();
  }

  return '';
}

export function sentenceCase(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

export function formatMoney(
  num,
  extraPrecision = 5,
  lock = false,
  accounting = true
) {
  const store = useStore();

  // Money with high precision below $1.00.
  if (extraPrecision === false) {
    extraPrecision = 2;
  } else if (extraPrecision === true) {
    extraPrecision = 5;
  }

  if (isNaN(+num)) {
    return num;
  }

  const currencyFormat = lock || store.currency;
  let val;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyFormat,
    minimumFractionDigits: 2,
    maximumFractionDigits: extraPrecision
  });

  if (accounting) {
    if (extraPrecision < 1 || Math.abs(+num) >= 1.0 || Math.abs(+num) === 0) {
      val = formatter.format(Math.round(Math.abs(+num) * 100) / 100);
    } else {
      val = formatter.format(Math.abs(+num)); // Print small values at high precision.
    }

    return +num < 0 ? `(${val})` : val; // accounting style output
  } else {
    if (extraPrecision < 1 || Math.abs(+num) >= 1.0 || Math.abs(+num) === 0) {
      val = formatter.format(Math.round(+num * 100) / 100);
    } else {
      val = formatter.format(+num); // Print small values at high precision.
    }

    return val;
  }
}

export function percentage(val) {
  return val + '%';
}

export function formatRecoupType(type) {
  if (type !== null && type !== undefined) {
    return type.toUpperCase();
  }

  return '';
}

export function formatYesNo(val) {
  if (val !== null && val !== undefined) {
    if (val) {
      return 'yes';
    } else {
      return 'no';
    }
  }

  return '';
}

export function currency(value) {
  return Number(value).toFixed(2);
}

export function percent(value, decimals = 0) {
  return (+value * 100).toFixed(decimals) + '%';
}

export function capitalize(string) {
  if (string) {
    return string.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}

export function kababCase(string) {
  string = string + '';
  // eslint-disable-next-line
  string = string.replace(/\s/g, '-').replace(/\%/g, 'percent').toLowerCase();

  if (string.match(/^\d/)) {
    return 'n' + string;
  }
  return string;
}

export function formatNumber(num, digits = 1) {
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];

  if (isNaN(+num)) {
    return '--';
  }

  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return Math.abs(num) >= item.value;
    });

  if (item) {
    var val =
      (Math.abs(num) / item.value).toFixed(digits).replace(rx, '$1') +
      item.symbol;

    if (+num < 0) {
      return `(${val})`;
    } else {
      return val;
    }
  }

  return '0';
}

export function formatPercentage(
  num,
  digits = 1,
  extraPrecision = 3,
  trimZeros = true
) {
  // Percentage with high precision below 0.01%.
  var val;

  if (isNaN(+num)) {
    return '--';
  }

  if (extraPrecision < 0 || Math.abs(+num) >= 0.01 || Math.abs(+num) === 0) {
    val = Math.abs(+num).toFixed(digits);
  } else {
    val = Math.abs(+num).toFixed(extraPrecision);
  }

  if (trimZeros) {
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

    val = val.replace(rx, '$1');
  }

  return +num < 0 ? `(${val})%` : `${val}%`; // accounting style output
}

export function formatCountryCode(val, unknownCode = 'ZZ') {
  if (val === null || val === undefined || val === unknownCode) {
    return 'Unnamed';
  } else if (val === 'others') {
    return 'Others';
  }

  var country = countriesQuery.getCountry(val);

  if (country && !country.startsWith('Wrong')) {
    return country;
  }

  return 'Unknown (' + val + ')';
}

export function convertHMS(hms) {
  if (hms && hms !== '0') {
    var p = String(hms).split(':'),
      s = 0,
      m = 1;

    while (p.length > 0) {
      s += m * parseInt(p.pop(), 10);
      m *= 60;
    }

    return s;
  }

  return 0;
}

export function formatHMS(seconds) {
  if (seconds && seconds !== '0') {
    var hms = new Date(seconds * 1000).toISOString().slice(11, 19);

    return hms.replace(/^00:/, '').replace(/^00:/, '').replace(/^0/, '');
  }

  return null;
}

export function formatUUID(uuid) {
  if (uuid) {
    if (!uuid.match(/^00000000/)) {
      return uuid.toUpperCase();
    } else {
      return 'n/a';
    }
  }

  return '--';
}

export function formatDate(val, showTime = false) {
  const format = showTime ? DateTime.DATETIME_SHORT : '';
  return val
    ? DateTime.fromMillis(+val * 1000)
        .setLocale(i18n.locale)
        .toLocaleString(format)
    : '--';
}

export function pluralize(i18nKey) {
  const formatKey = i18nKey.charAt(0).toUpperCase() + i18nKey.slice(1);
  const word = i18n.global.t(formatKey, 2);
  if (!word) return '';
  const lastChar = word.slice(-1);
  const lastTwoChars = word.slice(-2);
  if (
    lastChar === 'y' &&
    !['a', 'e', 'i', 'o', 'u'].includes(word[word.length - 2])
  ) {
    return word.slice(0, -1) + 'ies';
  } else if (
    lastTwoChars === 'ch' ||
    lastTwoChars === 'sh' ||
    lastChar === 'x' ||
    lastChar === 's' ||
    lastChar === 'z'
  ) {
    return word + 'es';
  } else {
    return word + 's';
  }
}

export async function copyText(text) {
  const store = useStore();
  if (navigator.clipboard && text) {
    await navigator.clipboard.writeText(text);

    const toastObj = {
      type: 'info',
      title: i18n.global.t('CopiedToClipboard'),
      content: text.toString(),
      cancelable: true,
      timer: 2
    };

    store.addToast(toastObj);
  }
}
