<script>
import VButton from '@/components/elements/button.vue';

export default {
  name: 'VColumnStandard',
  components: {
    VButton
  },
  props: {
    value: { type: [String, Number, Boolean, Array], default: null },
    format: { type: [Function, String], default: null },
    linkKey: { type: String, default: '' },
    linkPath: { type: String, default: '' },
    lock: { type: [String, Boolean], default: false }
  },
  computed: {
    formattedValue() {
      if (typeof this.format === 'function') {
        return this.format(this.value) || '';
      } else {
        switch (this.format) {
          case 'percentage':
            return this.value ? this.value.toFixed(2) + '%' : '0.00%';
        }

        if (this.value === null || this.value === undefined) {
          return '--';
        } else if (typeof this.value === 'object') {
          return this.value.join(', ');
        }

        return this.value || '';
      }
    }
  },
  methods: {
    goTo() {
      this.$router.push(`${this.linkPath}${this.linkKey}`);
    }
  }
};
</script>

<template>
  <div class="v-column-standard">
    <v-button
      v-if="linkKey !== null && (linkKey.length || linkPath.length)"
      :to="`${this.linkPath}${this.linkKey}`"
      variant="tertiary"
      justify="start"
    >
      {{ formattedValue }}
    </v-button>
    <span v-else>
      {{ formattedValue }}
    </span>
  </div>
</template>

<style lang="scss">
.v-column-standard {
  user-select: text;
  color: var(--hex_table-column-standard-text);
  font-size: 15px;

  &.status {
    > span {
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &.type,
  &.recording_cost {
    > span {
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .v-button__slot-container {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
