<script>
import { DateTime } from 'luxon';

export default {
  name: 'VDateColumn',
  props: {
    value: { type: Number, required: true }
  },
  computed: {
    date() {
      return DateTime.fromMillis(this.value * 1000).toFormat('L');
    }
  }
};
</script>

<template>
  <div class="v-date-time-column">
    <div class="date">{{ date }}</div>
  </div>
</template>

<style lang="scss">
.v-date-time-column {
  user-select: none;
  display: flex;
  align-items: baseline;

  .date {
  }
}
</style>
